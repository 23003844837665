.modalCarouselArrows {
  position: absolute;
  z-index: var(--z-index-3);
  bottom: 0;
  width: 100%;
  grid-column: 2 / span 8;
}

.innerWrapper {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: var(--spacer-32) 0 0;
  border-top: 0.1rem solid var(--arrow-keyline-color, var(--color-grey-50));
  gap: var(--spacer-24);

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 0.1rem;
    background-color: var(--color-grey-50);
    content: '';
    transform: scaleX(var(--controls-progress, 0));
    transform-origin: center left;
  }
}

.controlsButton {
  composes: button from '~typo';
  display: flex;
  align-items: center;
  padding: 0;
  cursor: pointer;
  transition: opacity var(--duration-300ms) var(--ease-basic-butter);

  --icon-size: 3.5rem;

  &:hover {
    --button-hover-progress: 1;
  }

  .controlsButtonTitle {
    color: var(--color-grey-100);
  }

  .controlsDash {
    padding: 0 var(--spacer-8);
  }

  .controlsButtonArrow {
    display: inline-block;
    width: 1.25rem;
    margin-left: var(--spacer-8);

    path {
      fill: var(--color-grey-100);
    }
  }

  &:first-child {
    .controlsButtonArrow {
      margin-right: var(--spacer-8);
      margin-left: 0;
    }
  }

  &:disabled {
    opacity: 0.1;
    pointer-events: none;
  }
}
