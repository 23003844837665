$ratio: 1 / 0.51;

.quoteInteractiveLg {
  composes: grid from '~g';
  position: relative;
}

.dummy {
  position: absolute;
  width: 100%;
  align-self: center;
  grid-column: start / end;
  pointer-events: none;
}

.titles {
  composes: title6 from '~typo';
  color: var(--color-grey-100);
}

.titles + .accents {
  margin-top: var(--spacer-8);
}

.accents {
  composes: body from '~typo';
}

.eyebrow {
  display: none;
}

.content {
  display: block; /* cancel Subgrid */
  padding-right: calc(var(--grid-computed-column-width) + var(--grid-gutter-size));
  grid-column: 3 / span 4;
}

.quoteInteractiveWrapper {
  position: relative;
  grid-column: 7 / end;
}

.quoteInteractive {
  .quoteInteractive {
    position: relative;

    --interactive-quotes-title-color: var(--color-grey-15);
    cursor: default;

    &:hover {
      --interactive-quotes-title-color: var(--color-grey-100);
    }
  }
}

.quoteInteractiveTitleWrapper {
  width: 100%;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

.quoteInteractiveTitle {
  composes: title2 from '~typo';
  color: var(--interactive-quotes-title-color);
  text-align: left;
  transition: color var(--duration-500ms) var(--ease-out);
}

.quoteInteractiveCardWrapper {
  position: absolute;
  z-index: var(--z-index-2);
  top: 50%;
  left: 0;
  pointer-events: none;
  transform: translate3d(var(--mouse-x), var(--mouse-y), 0);
  transition: transform var(--ease-out) var(--duration-300ms);
}

.quoteInteractiveGlowWrapper {
  opacity: var(--hover-progress, 0);
  transform: translate(calc(15% * var(--hover-progress)), calc(-50% + (var(--translate-y, 0) * 1px)));
  transform-origin: center center;
}

.quoteInteractiveCard {
  position: relative;
  display: flex;
  overflow: hidden;
  width: var(--expanded-width, 1080px);
  align-items: center;
  justify-content: center;
  padding: 0;
  border-radius: var(--border-radius-12);
  margin: 0;
  aspect-ratio: $ratio;
  transform-origin: center center;

  .hasInteracted & {
    width: 30vw; /* 433/1440 */
    max-width: 50rem;
    transform: scale(1, var(--scale-y, 1));

    .quoteInteractiveMedia {
      transform: scale(1, var(--inner-scale-y, 1));
    }

    .moreButton {
      transform: scale(1, var(--inner-scale-y, 1));
    }
  }

  .quoteInteractiveMedia,
  .quoteInteractiveLogo {
    pointer-events: none;
    transform-origin: center center;
    transition: none;
  }

  .quoteInteractiveMedia {
    position: relative;
    width: 100%;
    aspect-ratio: $ratio;
  }

  .quoteInteractiveLogo {
    position: absolute;
    top: auto;
    bottom: var(--spacer-24);
    left: var(--spacer-28);

    --graphic-scale: 1/2;
  }

  .quoteInteractiveMediaImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .moreButton {
    position: absolute;
    top: var(--spacer-16);
    right: var(--spacer-16);
    display: flex;
    width: 4rem;
    height: 4rem;
    align-items: center;
    justify-content: center;
    border: 0.1rem solid var(--color-alpha-light-15);
    border-radius: 50%;
    pointer-events: none;

    .buttonIcon {
      width: 1rem;
      height: 1rem;
    }
  }
}

.quoteInteractiveGradients {
  position: absolute;
  top: 0;
  left: 0;
  background: var(--interactive-quotes-gradient);
  background-clip: text;
  opacity: 1;
  pointer-events: none;
  -webkit-text-fill-color: transparent;
  transition: opacity var(--duration-500ms) ease-in-out;

  &.hidden {
    opacity: 0;
  }

  .quoteInteractive {
    /* Unset so that it doesn't prevent the gradient to apply */
    position: unset;
  }
}
