.radioButtons {
  position: relative;
}

.radioButtonItemsWrapper {
  display: flex;
  flex-flow: row nowrap;
}

.radioButtonItem {
  position: relative;
  margin-right: var(--spacer-28);
}

.radioButtonInner {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  white-space: nowrap;
}

.radioButtonIcon {
  position: relative;
  width: 2.4rem;
  height: 2.4rem;
  border: 1px solid var(--color-grey-60);
  border-radius: 1.2rem;
  margin-right: var(--spacer-12);

  .isActive & {
    &::after {
      position: absolute;
      top: calc(50% - 0.5rem);
      left: calc(50% - 0.5rem);
      width: 1rem;
      height: 1rem;
      border-radius: 0.5rem;
      background: var(--color-accent);
      content: '';
    }
  }
}

.radioButtonTitle,
.radioButtonSubtitle {
  composes: button from '~typo';
}

.radioButtonTitle {
  color: var(--color-grey-100);
}

.radioButtonSubtitle {
  padding: 0.1rem 1.2rem;
  padding-top: 0.3rem;
  border: 1px solid var(--color-accent);
  border-radius: 10rem;
  margin-left: var(--spacer-8);
  background-color: var(--color-accent);
  color: var(--grey-brand-grey-00, #000);
  font-family: var(--font-secondary);
  font-size: 0.9rem;
  font-weight: 100;
  text-transform: uppercase;
}
