.sideBySideStack {
  position: relative;

  .container {
    position: relative;
  }

  .wrapper {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;

    @media (--md) {
      display: block;
    }
  }

  .scrollingLockup {
    flex: 1 0 auto;

    @media (--md) {
      align-items: center;
    }
  }

  .paginationDots {
    position: absolute;
    z-index: var(--z-index-5);
    top: 0;
    left: 50%;
    transform: translateX(-50%);

    @media (--md) {
      display: none;
    }
  }
}
