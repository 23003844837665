.EndsWithModules_antiPaddingWrapper__mSjRz {
  position: relative;
}
.EndsWithModules_endsWithModules__2UM7Q {
  contain: paint;

  --module-padding-top: 0;
}
.EndsWithModules_endsWithModules__2UM7Q::after {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    height: 50vh;
    background: linear-gradient(to bottom, rgb(0 0 0 / 0%) 0%, rgb(0 0 0 / 100%) 100%);
    content: '';
  }
.EndsWithModules_canvas__ZUZBg {
  height: 100vh;
}
.EndsWithModules_overlayWrapper__x6iXk {
  position: absolute;
  inset: 0 0 -50vh 0;
}
.EndsWithModules_overlay__f271H {
  position: sticky;
  z-index: var(--z-index-1);
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  opacity: 1;
}
.EndsWithModules_modules__K0WCe {
  position: absolute;
  bottom: 0;
}
.EndsWithModules_sequenceWrapper__3Y9Ce {
  position: relative;

  --sequence-wrapper-height: calc(var(--sequence-height) - 100vh*0.5);
  height: var(--sequence-wrapper-height);
}
.EndsWithModules_sequence__U72fO {
  position: absolute;
  top: 0;
  left: 0;
}
.EndsWithModules_isRevealedModule__zQz0P .EndsWithModules_sequenceWrapper__3Y9Ce {
    position: relative;

    /* if the module is revealed by a parallax subtract the parallax overlap amount (set on the parent section) from the sequence height so it doesn't end too early */
    height: calc(var(--sequence-wrapper-height) - var(--parallax-start-y, 0px));
  }

.LaptopClose_antiPaddingWrapper__VQvy3 {
  position: relative;
  grid-column: wrapper-start / wrapper-end;
}
.LaptopClose_laptopCloseImageSequence__eV804 {
  position: relative;
}
@media (prefers-reduced-motion) {
    .LaptopClose_laptopCloseImageSequence__eV804 .LaptopClose_sequence__DB2Fi {
      height: 0;
      visibility: hidden;
    }

    .LaptopClose_laptopCloseImageSequence__eV804 .LaptopClose_modules__JHdC8:not(.LaptopClose_postSequence__Xc9Yd) {
      position: static;
      position: initial;
      padding-bottom: 0;
      aspect-ratio: unset;
      grid-column: 1 / span 12;
    }
  }
.LaptopClose_modules__JHdC8:not(.LaptopClose_postSequence__Xc9Yd) {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    aspect-ratio: 1260/862;
  }
@media (min-width: 768px) {
.LaptopClose_modules__JHdC8:not(.LaptopClose_postSequence__Xc9Yd) {
      justify-content: flex-end;
      padding-bottom: var(--spacer-96);
      aspect-ratio: 1260/839
  }
    }
.LaptopClose_modules__JHdC8.LaptopClose_postSequence__Xc9Yd {
    grid-column: wrapper-start / wrapper-end;
  }
.LaptopClose_modules__JHdC8.LaptopClose_postSequence__Xc9Yd::before {
      display: block;
      aspect-ratio: 1166/56;
      content: '';
      grid-column: start/end;
    }
.LaptopClose_desktop__b8W1I {
  display: none;
}
@media (min-width: 768px) {
.LaptopClose_desktop__b8W1I {
    display: grid
}
  }
.LaptopClose_desktop__b8W1I::after {
    display: block;
    aspect-ratio: 1166/56;
    content: '';
    grid-column: start/end;
  }
.LaptopClose_modulesWrapper__vMBPv {
  position: relative;
}
.LaptopClose_modulesWrapperMobile__1npnE {
  position: relative;
  grid-column: wrapper-start / wrapper-end;
}
.LaptopClose_mobile__kSkZw {
}
@media (min-width: 768px) {
.LaptopClose_mobile__kSkZw {
    display: none
}
  }
.LaptopClose_sequenceWrapper__Q_kAu {
  position: relative;
  z-index: var(--z-index-3);
  height: auto;
  grid-column: start / end;
  pointer-events: none;
}
.LaptopClose_sequence__DB2Fi {
  position: relative;

  /* By offsetting the sequence we make the intersection observer trigger later than expected */

  /* We then compensate by translating the canvas back by the same amount */
  top: 10vh;
}
@media (min-width: 768px) {
.LaptopClose_sequence__DB2Fi {
    top: 20vh;
}
  }
.LaptopClose_sequence__DB2Fi {
  height: auto;
  aspect-ratio: 1260/839;
  grid-column: start / end;
}
.LaptopClose_canvas__Yei1R {
  position: absolute;
  top: -10vh;
  width: 178.559%;
  translate: -21.95%;
}
@media (min-width: 768px) {
.LaptopClose_canvas__Yei1R {
    top: -20vh;
    width: 172.5%;
    translate: -20.95%
}
  }

.Prompter_prompter__sC6dq {
  position: relative;
  z-index: var(--z-index-neg);
  margin-top: var(--sequence-top-offset);
  margin-bottom: 0;
  opacity: 0;

  /* ensures the prompter doesn't prevent clicks down below */
  pointer-events: none;

  /* Size of hero card transition */
  --sequence-top-offset: -150vh;
  --canvas-height: 100vw;
  --covered-module-negative-bottom-margin: 100vh;
}

.Prompter_prompterSizer__SyjUz {
  position: absolute;
  inset: 0 0 100vh;
}

.Prompter_contentWrapper__urok9 {
  position: sticky;
  z-index: var(--z-index-1);
  top: 0;
  width: 100%;
  height: 100vh;
  align-items: center;
  margin-bottom: -100vh;
}

.Prompter_contentOverlay__yHRv3 {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100vh;
  background: linear-gradient(180deg, #000 18%, rgb(0 0 0 / 0%) 56.84%), linear-gradient(180deg, rgb(0 0 0 / 0%) 0%, #000 100%);
  opacity: 0.8;
  pointer-events: none;
}

.Prompter_textWrapper__rLdHf {

  /* ensures the prompter doesn't prevent clicks down below */
  pointer-events: all;
}

.Prompter_sequence__NV6I1 {
  --section-overlap-top: calc(100vh + var(--corner-radius));
  --covered-module-negative-bottom-margin: -100vh;
}

.Underlay_noPaddingWrapper___3aI_ {
  --sequence-top-offset: -600px;
  --sequence-height-factor: 0.8;
  --canvas-height: 100vw;
  --canvas-width: var(--canvas-height);
  position: relative;
  z-index: var(--z-index-neg);
  top: var(--sequence-top-offset);
  margin-bottom: calc(var(--sequence-top-offset) - var(--sequence-2-height)*var(--sequence-height-factor)*1px - 50vh + var(--canvas-height)/2 - var(--canvas-height)*var(--sequence-offset, 0)/200);
}
  @media (min-width: 768px) {.Underlay_noPaddingWrapper___3aI_ {
    --sequence-top-offset: -900px;
    --canvas-height: 900px
}
  }
  @media (min-height: 950px) {.Underlay_noPaddingWrapper___3aI_ {
    --sequence-height-factor: 1
}
  }
  @media (min-height: 1200px) {.Underlay_noPaddingWrapper___3aI_ {
    --sequence-height-factor: 1.2
}
  }
  @media (min-height: 1400px) {.Underlay_noPaddingWrapper___3aI_ {
    --sequence-height-factor: 1.4
}
  }
  @media (min-height: 1600px) {.Underlay_noPaddingWrapper___3aI_ {
    --sequence-height-factor: 1.6
}
  }
  .Underlay_noPaddingWrapper___3aI_ .Underlay_sequence__MVRQD {
    --sequence-local-height: var(--sequence-1-height);
    opacity: 0;
  }
  .Underlay_noPaddingWrapper___3aI_ .Underlay_sequence__MVRQD .Underlay_canvas__RUPQj {
      top: calc(100vh - var(--canvas-height));
      translate: 0 calc(-50% - 50vh + var(--canvas-height));
    }
  .Underlay_noPaddingWrapper___3aI_ .Underlay_sequence2__inheo {
    position: relative;

    --sequence-local-height: var(--sequence-2-height);
    opacity: 0;
  }
  .Underlay_noPaddingWrapper___3aI_ .Underlay_sequence2__inheo .Underlay_canvas__RUPQj {
      position: absolute;
      top: auto;
      bottom: calc(100% + (100vh - var(--canvas-height))/2);
      left: 50%;
      translate: -50% 0%;
    }
  .Underlay_noPaddingWrapper___3aI_ .Underlay_sequence__MVRQD,
  .Underlay_noPaddingWrapper___3aI_ .Underlay_sequence2__inheo {
    height: calc(var(--sequence-local-height)*1px*var(--sequence-height-factor));
    text-align: center;
  }
  .Underlay_noPaddingWrapper___3aI_ .Underlay_sequence__MVRQD .Underlay_canvas__RUPQj, .Underlay_noPaddingWrapper___3aI_ .Underlay_sequence2__inheo .Underlay_canvas__RUPQj {
      width: var(--canvas-width);
      height: var(--canvas-height);
      -o-object-fit: contain;
         object-fit: contain;
    }

.LiveSchedule_liveScheduleContainer__0dRlm {
}

.LiveSchedule_liveScheduleContent__pUUNF {
  grid-column: start / end;
}

@media (min-width: 768px) {

.LiveSchedule_liveScheduleContent__pUUNF {
    grid-column: start / span 12
}
  }

.LiveSchedule_liveScheduleNavigation___NYkh {
  --bg: rgb(27 27 38 / 100%);
  position: relative;
  overflow: hidden;
  border-radius: var(--border-radius-6);
  background-color: var(--bg);
}

.LiveSchedule_liveScheduleNavigation___NYkh::after {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, var(--bg) 0%, rgb(0 0 0 / 0%) 10%, rgb(0 0 0 / 0%) 90%, var(--bg) 100%);
    content: '';
    opacity: 0;
    pointer-events: none;
    transition: opacity var(--duration-200ms) var(--ease-basic-butter);
  }

@media (min-width: 768px) {

.LiveSchedule_liveScheduleNavigation___NYkh::after {
      background: linear-gradient(90deg, var(--bg) 0%, rgb(0 0 0 / 0%) 6%, rgb(0 0 0 / 0%) 94%, var(--bg) 100%)
  }
    }

.LiveSchedule_liveScheduleNavigationOverflow__XBjQa {
  overflow-x: scroll;
}

.LiveSchedule_liveScheduleNavigationOverflow__XBjQa::after {
    opacity: 1;
  }

.LiveSchedule_liveScheduleNavigation___NYkh ol {
  display: flex;
  overflow: hidden;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: left;
  margin: 0;
}

.LiveSchedule_liveScheduleNavigation___NYkh ol li {
    min-width: var(--spacer-240);
    text-align: center;
  }

.LiveSchedule_liveScheduleNavigationOverflow__XBjQa ol {
  justify-content: space-between;
}

.LiveSchedule_liveScheduleEventButton__RKgNG button {
  padding: var(--spacer-24);
  color: var(--color-grey-60);
  transition: color var(--duration-200ms) var(--ease-basic-butter);
  white-space: nowrap;
}

.LiveSchedule_liveScheduleEventButton__RKgNG button:disabled {
    opacity: 0.5;
    pointer-events: none;
  }

.LiveSchedule_liveScheduleSelectedEventButton__65hdE button {
  color: var(--color-grey-88);
}

.LiveSchedule_liveScheduleEventDetails__SmXJr {
  margin-top: var(--spacer-32);
}

.LiveSchedule_eventTitlePortableText__Fsri0 p {
    grid-column: start / span 12;
  }

.LiveSchedule_speakersList__x6IfO {
  display: flex;
  flex-flow: column wrap;
  align-items: center;
}

.LiveSchedule_speakersList__x6IfO li {
    width: 100%;
  }

@media (min-width: 768px) {

.LiveSchedule_speakersList__x6IfO {
    width: auto;
    flex-direction: row
}
  }

.LiveSchedule_speakersList__x6IfO .LiveSchedule_speakerItem__br9I0 {
    min-width: 50%;
    margin-top: var(--spacer-32);
  }

@media (min-width: 768px) {

.LiveSchedule_speakersList__x6IfO .LiveSchedule_speakerItem__br9I0 {
      width: 50%
  }
    }

.LiveSchedule_speaker__pATW9 {
  display: flex;
  align-items: center;
}

.LiveSchedule_speaker__pATW9 p {
    margin-top: 0;
  }

.LiveSchedule_speaker__pATW9 .LiveSchedule_speakerAvatar__XxGj7 {
    margin-right: var(--spacer-32);
  }

.LiveSchedule_speaker__pATW9 .LiveSchedule_speakerAvatar__XxGj7 img {
      width: var(--spacer-64);
      height: var(--spacer-64);
      border-radius: 50%;
    }

.LiveSchedule_eventCta__3460z {
  margin: var(--spacer-32) 0;
}

.LiveSchedule_nonGridPortableText__o3oqs {
  display: block;
}

.LiveSchedule_nonGridPortableText__o3oqs p {
    width: 100%;
  }

.LogoTileRow_row__SYwuY {
  display: flex;
  width: 100%;
  flex-flow: row nowrap;
  align-items: center;
  margin-bottom: var(--spacer-10);
  margin-left: calc(100vw - 43.5%);
  gap: var(--spacer-10);
}

  @media (min-width: 768px) {.LogoTileRow_row__SYwuY {
    margin-bottom: var(--spacer-12);
    margin-left: calc(100vw - 54.2%);
    gap: var(--spacer-12)
}
  }

  @media (min-width: 1240px) {.LogoTileRow_row__SYwuY {
    margin-left: -2rem
}
  }

  .LogoTileRow_row__SYwuY:nth-child(2n) {
    margin-left: calc(100vw - 33.7%);
  }

  @media (min-width: 768px) {

  .LogoTileRow_row__SYwuY:nth-child(2n) {
      margin-left: calc(100vw - 46%)
  }
    }

  @media (min-width: 1240px) {

  .LogoTileRow_row__SYwuY:nth-child(2n) {
      margin-left: -8vw
  }
    }

  .LogoTileRow_row__SYwuY:last-of-type {
    margin-bottom: 0;
    margin-bottom: initial;
  }

.LogoTileRow_duplicate__8C5tZ {
  display: flex;
  flex-flow: row nowrap;
  gap: var(--spacer-10);
}

@media (min-width: 768px) {

.LogoTileRow_duplicate__8C5tZ {
    gap: var(--spacer-12)
}
  }

.LogoTileRow_logoTile__pZuVv {
  position: relative;
  display: flex;
  width: var(--tile-width);
  min-width: 21.2rem;
  max-width: 30.2rem;
  height: var(--tile-height);
  flex-flow: row nowrap;
  padding: 0 var(--spacer-28);
  border: 0.05rem solid var(--color-alpha-perano-10);
  border-radius: var(--tile-border-radius);
  background-color: var(--color-grey-05);
}

@media (min-width: 1240px) {

.LogoTileRow_logoTile__pZuVv {
    max-width: none
}
  }

.LogoTileRow_logoWrapper__XVFwO {
  position: relative;
  display: flex;
  width: 2.6rem;
  height: 100%;
  align-items: center;
  justify-content: center;
  margin-right: var(--spacer-24);
}

@media (min-width: 768px) {

.LogoTileRow_logoWrapper__XVFwO {
    --graphic-scale: 32 / 26;
    width: 3.2rem
}
  }

.LogoTileRow_logo__TS6vC {
  display: flex;
}

.LogoTileRow_label__5Kpmr {
  display: inline-block;
  align-self: center;
  color: var(--color-grey-80);
  line-height: var(--tile-height);
}

.LogoTileWall_logoTileWall__sUGpp {
  --tile-border-radius: var(--border-radius-10);
  --tile-height: 8.4rem;
  --tile-width: auto;
  position: relative;
  display: flex;
}

  @media (min-width: 768px) {.LogoTileWall_logoTileWall__sUGpp {
    --tile-height: 10.2rem;
    --tile-width: 31vw /* 100vw / 3 */
}
  }

  @media (min-width: 1240px) {.LogoTileWall_logoTileWall__sUGpp {
    --tile-width: 21vw /* 100vw / 4.5 */
}
  }

.LogoTileWall_container__Xdu_I {
  position: relative;
}

.Manifesto_manifesto__eNpEE {
  --rounded-top-padding: 0;
  --scroll-progress-opacity: 0;
  --scroll-progress-y: 0;
}

.Manifesto_container__JkZvd {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  align-content: flex-end;
}

@media (min-width: 768px) {

.Manifesto_container__JkZvd {
    height: auto;
    height: initial;

    /* aspect ratio of 1.77 since width will always be 100vw */
    min-height: 56.5vw;
    align-content: center
}
  }

.Manifesto_container__JkZvd .Manifesto_body__9yrWr,
  .Manifesto_container__JkZvd .Manifesto_button__Uq2Dq {
    color: var(--color-grey-100);
  }

.Manifesto_container__JkZvd .Manifesto_text__Z1QAm {
    margin-bottom: var(--spacer-24);
    color: var(--color-grey-100);
  }

@media (min-width: 768px) {

.Manifesto_container__JkZvd .Manifesto_text__Z1QAm {
      margin-bottom: var(--spacer-32);
      text-align: center
  }
    }

@media (min-width: 1240px) {

.Manifesto_container__JkZvd .Manifesto_text__Z1QAm {
      margin-bottom: var(--spacer-48)
  }
    }

.Manifesto_mediaWrapper__xn4Vm {
  position: absolute;
  overflow: hidden;
  max-height: calc(100vh - var(--spacer-48)*2);
  border-radius: var(--border-radius-10);
  inset: var(--spacer-48);
  transform: scale(var(--manifesto-video-scale, 1));
  transition: transform var(--duration-600ms) var(--ease-basic-butter);
}

.Manifesto_mediaWrapper__xn4Vm.Manifesto_isActive__KwXMA {
    transform: scale(1);
  }

.Manifesto_videoWrapper__yeZ17 {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  inset: 0;
  opacity: 0;
  transition: opacity var(--duration-600ms) var(--ease-basic-butter);
}

.Manifesto_videoWrapper__yeZ17.Manifesto_isActive__KwXMA {
    opacity: 1;
  }

.Manifesto_backgroundWrapper__ZGLBw,
.Manifesto_backgroundImage__3QKeB {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.Manifesto_backgroundWrapper__ZGLBw {
  background: var(--color-grey-00);
  opacity: 1;
  transform: scale(1.1);
  transition:
    opacity var(--duration-600ms) var(--ease-basic-butter),
    transform var(--duration-600ms) var(--ease-basic-butter);
}

.Manifesto_backgroundWrapper__ZGLBw.Manifesto_isHidden__lZU_f {
    opacity: 0;
    pointer-events: none;
    transform: scale(1);
    transition:
      opacity var(--duration-600ms) var(--ease-basic-butter) var(--duration-400ms),
      transform var(--duration-600ms) var(--ease-basic-butter);
  }

.Manifesto_closeButton__XzhPT {
  position: absolute;
  z-index: var(--z-index-2);
  top: var(--spacer-12);
  right: var(--spacer-12);
  width: var(--spacer-40);
  height: var(--spacer-40);
  box-shadow: var(--button-box-shadow);
}

@media (min-width: 768px) {

.Manifesto_closeButton__XzhPT {
    top: var(--spacer-40);
    right: var(--spacer-40)
}
  }

.Manifesto_backgroundImage__3QKeB {
  height: calc(100% + 12vh);
  opacity: var(--scroll-progress-opacity);
  transform: translate3d(0, var(--scroll-progress-y), 0);
}

.Manifesto_contentWrapper___cFxy {
  --content-hidden-progress: 0;
  position: relative;
  height: 100%;
  padding-bottom: var(--spacer-32);
  grid-column: start / end;
}

.Manifesto_contentWrapper___cFxy.Manifesto_isHidden__lZU_f {
    --content-hidden-progress: 1;
    pointer-events: none;
  }

@media (min-width: 768px) {

.Manifesto_contentWrapper___cFxy {
    padding: var(--spacer-124) 0
}
  }

.Manifesto_text__Z1QAm {
  opacity: calc(1 - var(--content-hidden-progress));
  transform: translateY(calc(var(--content-hidden-progress)*1rem));
  transition:
    opacity var(--duration-600ms) var(--ease-basic-butter),
    transform var(--duration-600ms) var(--ease-basic-butter);
  transition-delay: var(--duration-100ms);
}

.Manifesto_body__9yrWr {
}

.Manifesto_buttonWrapper__RE1WM {
  position: relative;
  display: flex;
  grid-column: start / end;
  grid-row: 2;
  opacity: calc(1 - var(--content-hidden-progress));
  place-content: flex-start;
  transform: translateY(calc(var(--content-hidden-progress)*2rem));
  transition:
    opacity var(--duration-600ms) var(--ease-basic-butter),
    transform var(--duration-600ms) var(--ease-basic-butter);
  transition-delay: var(--duration-150ms);
}

@media (min-width: 768px) {

.Manifesto_buttonWrapper__RE1WM {
    place-content: center
}
  }

.Manifesto_button__Uq2Dq {
  position: relative;
  display: flex;
  align-items: center;
}

.Manifesto_button__Uq2Dq:hover {
    --item-hover-progress: 1;
  }

.Manifesto_button__Uq2Dq:hover path {
      fill: var(--button-hover-color);
    }

.Manifesto_buttonLabel__Rm_kP {
  align-self: center;
  padding-left: var(--spacer-16);
  white-space: nowrap;
}

.MediaWithText_mediaWithText__y6X41 {
  position: relative;
  width: 100%;
  margin: 0;

  --media-with-text-progress: 0;
  --max-parallax-percent: 0;
}

  @media (min-width: 768px) {.MediaWithText_mediaWithText__y6X41 {
    grid-column: start / end
}
  }

.MediaWithText_contentWrapper__S_CNw {
  position: relative;
  z-index: var(--z-index-1);
  width: 100%;
  height: 100%;
}

.MediaWithText_mediaContainer__yDK4h {
  position: absolute;
  overflow: hidden;
  inset: 0;
}

.MediaWithText_hasRoundedBorders__QKMJO {
  overflow: hidden;
  border-radius: var(--border-radius-5);
}

.MediaWithText_mediaWrapper__T2V9S {
  --default-media-start: start;
  --default-media-end: end;
  position: relative;
  display: flex;
  width: 100%;
  grid-column: var(--media-start, var(--default-media-start)) / var(--media-end, var(--default-media-end));
}

@media (min-width: 768px) {

.MediaWithText_mediaWrapper__T2V9S {
    --default-media-start: col 2;
    --default-media-end: col 14;
    width: auto
}
  }

@media (min-width: 1240px) {

.MediaWithText_mediaWrapper__T2V9S:not(.MediaWithText_macbookPro__wV9eQ, .MediaWithText_studioDisplay__22LzY) {
      --default-media-start: start;
      --default-media-end: end
  }
    }

.MediaWithText_mediaWrapper__T2V9S:not(.MediaWithText_macbookPro__wV9eQ, .MediaWithText_studioDisplay__22LzY) .MediaWithText_contentWrapper__S_CNw {
      overflow: hidden;
    }

.MediaWithText_mediaWrapper__T2V9S:not(.MediaWithText_macbookPro__wV9eQ, .MediaWithText_studioDisplay__22LzY) .MediaWithText_mediaContainer__yDK4h {
      position: relative;
      overflow: hidden;
    }

.MediaWithText_mediaWrapper__T2V9S:not(.MediaWithText_macbookPro__wV9eQ, .MediaWithText_studioDisplay__22LzY) .MediaWithText_media__Pe14Q {
      height: 100%;
      aspect-ratio: var(--media-original-aspect-ratio);
      transition: transform var(--duration-150ms) var(--ease-out);
    }

@media (min-width: 768px) {

.MediaWithText_mediaWrapper__T2V9S:not(.MediaWithText_macbookPro__wV9eQ, .MediaWithText_studioDisplay__22LzY) .MediaWithText_media__Pe14Q:not(.MediaWithText_noMediaParallax___UnKM) {
          transform: translate3d(0, calc((var(--media-with-text-progress) - 0.5)*(-1%*var(--max-parallax-percent))), 0) scale(calc(1 + var(--max-parallax-percent)/100))
      }
        }

.MediaWithText_media__Pe14Q {
  width: 100%;
  height: 100%;
}

.MediaWithText_isMobileLeftAligned__THbNI,
.MediaWithText_isMobileRightAligned__V5yod {
  flex-shrink: 0;
}

.MediaWithText_isMobileLeftAligned__THbNI .MediaWithText_mediaWrapper__T2V9S, .MediaWithText_isMobileRightAligned__V5yod .MediaWithText_mediaWrapper__T2V9S {
    width: min(166%, 60rem);
  }

/* TODO: sm has been deprecated (media with text) */

@media (min-width: 768px) {

.MediaWithText_isMobileLeftAligned__THbNI .MediaWithText_mediaWrapper__T2V9S, .MediaWithText_isMobileRightAligned__V5yod .MediaWithText_mediaWrapper__T2V9S {
      width: auto
  }
    }

.MediaWithText_isMobileLeftAligned__THbNI .MediaWithText_media__Pe14Q, .MediaWithText_isMobileRightAligned__V5yod .MediaWithText_media__Pe14Q {
    aspect-ratio: var(--media-original-aspect-ratio);
  }

.MediaWithText_isMobileRightAligned__V5yod .MediaWithText_mediaWrapper__T2V9S {
    justify-self: flex-start;
  }

@media (min-width: 768px) {

.MediaWithText_isMobileRightAligned__V5yod .MediaWithText_mediaWrapper__T2V9S {
      justify-self: initial
  }
    }

.MediaWithText_isMobileLeftAligned__THbNI .MediaWithText_mediaWrapper__T2V9S {
    justify-self: flex-end;
  }

@media (min-width: 768px) {

.MediaWithText_isMobileLeftAligned__THbNI .MediaWithText_mediaWrapper__T2V9S {
      justify-self: initial
  }
    }

.MediaWithText_textContent__PbxZ7 {
  z-index: var(--z-index-1);
  padding: 0;
  margin-top: var(--spacer-40);
}

@media (min-width: 768px) {

.MediaWithText_textContent__PbxZ7 {
    margin-top: var(--spacer-48)
}
  }

.OrganicMosaic_mosaic__ZCFxJ {
  --module-padding-bottom: 0;

  /* this value gets multiplied by -1 in the module wrapper, so we're actually making it a positive value here */
  --covered-module-negative-bottom-margin: -1 * var(--spacer-84);
}
  @media (min-width: 768px) {.OrganicMosaic_mosaic__ZCFxJ {
    --covered-module-negative-bottom-margin: calc(var(--margin-with-parallax)/3)
}
  }

.OrganicMosaic_container__huorC {
  position: relative;
}

.OrganicMosaic_mosaicContainer__Qux3K {
  position: relative;
  display: flex;
  width: calc(var(--eight-cols) + var(--content-width) + var(--grid-gutter-size));
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-top: calc(var(--margin-with-parallax)*-1);
  margin-left: calc(var(--four-cols)*-1 - var(--grid-gutter-size)/2);
  -moz-column-gap: var(--spacer-24);
       column-gap: var(--spacer-24);
  grid-column: start / end;
  pointer-events: none;

  --content-width: 100%;
  --eight-cols: (var(--content-width) / var(--grid-number-of-columns)) * 8;
  --four-cols: (var(--content-width) / var(--grid-number-of-columns)) * 4;
}

@media (min-width: 768px) {

.OrganicMosaic_mosaicContainer__Qux3K {
    display: grid;
    margin-top: calc(var(--margin-with-parallax)*-1/2.5);
    grid-column-gap: var(--grid-gutter-size);
    -moz-column-gap: var(--grid-gutter-size);
         column-gap: var(--grid-gutter-size);
    grid-template-columns: repeat(20, 1fr)
}
  }

.OrganicMosaic_itemsDesktop__44w3R {
  display: none;
}

@media (min-width: 768px) {

.OrganicMosaic_itemsDesktop__44w3R {
    display: grid
}
  }

.OrganicMosaic_itemsMobile__evElO {
  display: grid;
}

@media (min-width: 768px) {

.OrganicMosaic_itemsMobile__evElO {
    display: none
}
  }

.OrganicMosaic_column__SEmSP {
  position: relative;
  display: flex;
  width: 23%;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
}

@media (min-width: 768px) {

.OrganicMosaic_column__SEmSP {
    top: var(--offset-y, 0);
    width: auto;
    width: initial;
    grid-column: span 4;
    row-gap: var(--grid-gutter-size);
}

    .OrganicMosaic_column__SEmSP:nth-child(1) {
      --offset-y: -1rem;
    }

    .OrganicMosaic_column__SEmSP:nth-child(2) {
      --offset-y: 3rem;
    }

    .OrganicMosaic_column__SEmSP:nth-child(4) {
      --offset-y: 1.3rem;
    }
  }

.OrganicMosaic_column__SEmSP {
  row-gap: var(--spacer-24);
}

.OrganicMosaic_item__fVuBi {
  position: relative;
  overflow: hidden;
  width: 100%;
  flex-shrink: 0;
  background-color: var(--color-alpha-black-100);
  gap: 20px;
  -webkit-mask-image: var(--top-bottom-mask-10px);
          mask-image: var(--top-bottom-mask-10px);
}

.Pothole_pothole__v2D8x {
  position: relative;
  overflow: hidden;

  --title-4-pothole-column-start: col 1;
  --covered-module-negative-bottom-margin: 0;

  /* calculate height based on base aspect ratio plus any additional padding needed to accomodate for overlap and parallax */
  min-height: calc(85vw/var(--pothole-aspect-ratio, 0.56) + var(--section-overlap-top, 0px) + var(--section-overlap-bottom, 0px));
  align-items: end;
}

  @media (min-width: 768px) {.Pothole_pothole__v2D8x {
    --pothole-aspect-ratio: 1.6;
}
  }

  @media (min-width: 1240px) {.Pothole_pothole__v2D8x {
    --pothole-aspect-ratio: 1.8;
}
  }

  .Pothole_pothole__v2D8x {

  --module-padding-top: 0;
  --module-padding-bottom: 0;
}

.Pothole_container__dtiXQ {
  grid-column: start / end;
}

.Pothole_backgroundContainer__Oy_34 {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.Pothole_background__a2b5t {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 114%;
  -o-object-fit: cover;
     object-fit: cover;

  /* translate the image from -y value to 0 */
  transform: translateY(calc(var(--pothole-progress, 0)*(85vw/var(--pothole-aspect-ratio, 0.56)*0.28 + var(--section-overlap-top, 0px)*0.28 + var(--section-overlap-bottom, 0px)*0.28)/1 - 85vw/var(--pothole-aspect-ratio, 0.56)*0.28 - var(--section-overlap-top, 0px)*0.28 - var(--section-overlap-bottom, 0px)*0.28));
  transform-origin: top;
}

.Pothole_backgroundOverlay__sGeTa {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.Pothole_overlay__3zyVU {
  z-index: var(--z-index-1);
}

.Pothole_contentContainer__e8d2t {
  z-index: var(--z-index-4);
  padding-top: var(--spacer-480);

  /* padding bottom here is to counteract the garage door */
  padding-bottom: var(--spacer-32);
  margin-bottom: var(--spacer-124);
  grid-column: start / end;

  --body-xl-block-column-start: start;
  --body-xl-block-graphic-margin-bottom: var(--spacer-12);
}

.PotholeV4_potholeV4__NAEGE {
  position: relative;

  /* By default, overflowing potholes should always sits at z-index 0 if never overriden */
  z-index: var(--module-z-index, var(--z-index-0));

  /* calculate height based on base aspect ratio plus any additional padding needed to accomodate for overlap and parallax */
  align-items: end;
  padding-top: var(--module-padding-top);
  padding-bottom: var(--module-padding-bottom);

  --module-padding-bottom: var(--spacer-64);
  --module-padding-top: var(--spacer-64);
}

  .PotholeV4_potholeV4__NAEGE.PotholeV4_isLastModule__WCzEq {
    /* hack to avoid the clip created by svg mask */
    --module-padding-bottom: var(--spacer-320);
    --module-padding-top: 0;
  }

  .PotholeV4_potholeV4__NAEGE.PotholeV4_isFirstModule__tk_tO {
    /* extra padding for the VC to peek through */
    --module-padding-top: 29.6rem;
    --module-padding-bottom: 0;
  }

  @media (min-width: 768px) {.PotholeV4_potholeV4__NAEGE {
    --pothole-aspect-ratio: 1.6;
    min-height: 70rem
}

    .PotholeV4_potholeV4__NAEGE.PotholeV4_isFirstModule__tk_tO {
      --module-padding-top: 0;
    }

    .PotholeV4_potholeV4__NAEGE.PotholeV4_isLastModule__WCzEq {
      min-height: calc(70rem + var(--module-padding-bottom));

      /* apply the parallax as negative margin */
      --covered-module-negative-bottom-margin: var(--parallax-start-y);
      --module-padding-bottom: calc(var(--covered-module-negative-bottom-margin)*0.5);
    }
  }

  @media (min-width: 1240px) {.PotholeV4_potholeV4__NAEGE {
    --pothole-aspect-ratio: 1.8
}
  }

.PotholeV4_container__3qwQ7 {
  grid-column: start / end;
}

.PotholeV4_backgroundGrid__01bf6 {
  grid-column: start / end;
}

.PotholeV4_mediaAlignBottom__yJJps .PotholeV4_backgroundGrid__01bf6 {
    grid-row: 2;
  }

@media (min-width: 768px) {

.PotholeV4_mediaAlignBottom__yJJps .PotholeV4_backgroundGrid__01bf6 {
      grid-row: unset
  }
    }

@media (min-width: 768px) {

.PotholeV4_backgroundGrid__01bf6 {
    position: absolute;
    z-index: var(--z-index-0);
    grid-column: unset;
    inset: 0
}
  }

.PotholeV4_backgroundContainer__KykGO {
  position: relative;
  height: 100%;
}

.PotholeV4_backgroundContainer__KykGO.PotholeV4_overflow__wk_4U .PotholeV4_backgroundOverlay__X9cHw {
      bottom: calc(var(--background-offset)*-1);
      overflow: visible;
      height: var(--pothole-media-size);
      max-height: 150rem;
      aspect-ratio: 1;

      --pothole-media-size: 150vw;
    }

@media (min-width: 768px) {

.PotholeV4_backgroundContainer__KykGO.PotholeV4_overflow__wk_4U .PotholeV4_backgroundOverlay__X9cHw {
        overflow: visible;
        max-height: 150rem;

        --pothole-media-size: calc(var(--grid-computed-margin-width)*2 + var(--grid-computed-inner-width))
    }
      }

.PotholeV4_isFirstModule__tk_tO .PotholeV4_backgroundContainer__KykGO.PotholeV4_overflow__wk_4U .PotholeV4_backgroundOverlay__X9cHw,
    .PotholeV4_mediaAlignTop__JC5cT .PotholeV4_backgroundContainer__KykGO.PotholeV4_overflow__wk_4U .PotholeV4_backgroundOverlay__X9cHw {
      bottom: calc(var(--background-offset)*-1);
    }

@media (min-width: 768px) {

.PotholeV4_isFirstModule__tk_tO .PotholeV4_backgroundContainer__KykGO.PotholeV4_overflow__wk_4U .PotholeV4_backgroundOverlay__X9cHw,
    .PotholeV4_mediaAlignTop__JC5cT .PotholeV4_backgroundContainer__KykGO.PotholeV4_overflow__wk_4U .PotholeV4_backgroundOverlay__X9cHw {
        top: auto;
        bottom: calc(var(--background-offset)*-1)
    }
      }

.PotholeV4_isLastModule__WCzEq .PotholeV4_backgroundContainer__KykGO.PotholeV4_overflow__wk_4U .PotholeV4_backgroundOverlay__X9cHw,
    .PotholeV4_mediaAlignBottom__yJJps .PotholeV4_backgroundContainer__KykGO.PotholeV4_overflow__wk_4U .PotholeV4_backgroundOverlay__X9cHw {
      top: calc(var(--background-offset)*-1);
    }

@media (min-width: 768px) {

.PotholeV4_isLastModule__WCzEq .PotholeV4_backgroundContainer__KykGO.PotholeV4_overflow__wk_4U .PotholeV4_backgroundOverlay__X9cHw,
    .PotholeV4_mediaAlignBottom__yJJps .PotholeV4_backgroundContainer__KykGO.PotholeV4_overflow__wk_4U .PotholeV4_backgroundOverlay__X9cHw {
        top: calc(var(--background-offset)*-1);
        bottom: auto
    }
      }

.PotholeV4_backgroundContainer__KykGO.PotholeV4_fullWidth__Z3qyM {
    position: absolute;
    inset: 0;
  }

.PotholeV4_backgroundContainer__KykGO.PotholeV4_fullWidth__Z3qyM .PotholeV4_backgroundOverlay__X9cHw {
      width: 100%;
    }

.PotholeV4_backgroundContainer__KykGO.PotholeV4_rightAligned__hToF2 {
    grid-column: start / end;
  }

.PotholeV4_backgroundContainer__KykGO.PotholeV4_rightAligned__hToF2 .PotholeV4_backgroundOverlay__X9cHw {
      /* need to be center in the middle of the screen on small */
      right: 50%;
      left: auto;
      aspect-ratio: 1;
      transform: translate(var(--pothole-media-translate-x), 0);

      --pothole-media-translate-x: 50%;
    }

@media (min-width: 768px) {

.PotholeV4_backgroundContainer__KykGO.PotholeV4_rightAligned__hToF2 .PotholeV4_backgroundOverlay__X9cHw {
        /* right aligned */
        right: 0;

        --pothole-media-translate-x: calc(50% - var(--grid-computed-column-width))
    }
      }

.PotholeV4_backgroundOverlay__X9cHw {
  /* Handles the offset of the pothole media asset from the top/bottom of the container */
  --background-offset: 5rem;
  position: absolute;
  left: 0;
  overflow: hidden;
  height: 100%;
}

.PotholeV4_isFirstModule__tk_tO .PotholeV4_backgroundOverlay__X9cHw {
    --background-offset: 5rem;
  }

@media (min-width: 768px) {

.PotholeV4_isFirstModule__tk_tO .PotholeV4_backgroundOverlay__X9cHw {
      --background-offset: 1rem
  }
    }

@media (min-width: 1240px) {

.PotholeV4_isFirstModule__tk_tO .PotholeV4_backgroundOverlay__X9cHw {
      --background-offset: 20rem
  }
    }

.PotholeV4_isLastModule__WCzEq .PotholeV4_backgroundOverlay__X9cHw {
    --background-offset: 5rem;
  }

@media (min-width: 768px) {

.PotholeV4_isLastModule__WCzEq .PotholeV4_backgroundOverlay__X9cHw {
      --background-offset: 1rem
  }
    }

@media (min-width: 1240px) {

.PotholeV4_isLastModule__WCzEq .PotholeV4_backgroundOverlay__X9cHw {
      --background-offset: 0
  }
    }

.PotholeV4_backgroundOverlay__X9cHw .PotholeV4_backgroundMedia__YUy0F {
    transform: scale(var(--media-scale, 1));
  }

.PotholeV4_backgroundOverlay__X9cHw::after {
    position: absolute;

    /* custom gradient overlay */
    background: linear-gradient(0deg, rgb(0 0 0 / 20%) 0%, rgb(0 0 0 / 20%) 100%), linear-gradient(19deg, rgb(0 0 0 / 67%) 21.85%, rgb(0 0 0 / 0%) 73.55%);
    content: '';
    inset: 0;
  }

.PotholeV4_overlay__9Y5jC {
  z-index: var(--z-index-1);
}

.PotholeV4_content__ykTS4 {
  align-self: center;
  padding: 5.6rem 0;
}

.PotholeV4_topAligned__KbwYv .PotholeV4_content__ykTS4 {
    align-self: start;
  }

.PotholeV4_bottomAligned__rAXqB .PotholeV4_content__ykTS4 {
    align-self: end;
  }

.PotholeV4_potholeGlow__SDog2 {
  position: absolute;
  z-index: var(--z-index-3);
  background: var(--glow-background, transparent);
  inset: -20rem;
  mix-blend-mode: screen;
  pointer-events: none;
}

@media (min-width: 768px) {

.PotholeV4_potholeGlow__SDog2 {
    inset: -30rem
}
  }

.RadioButtons_radioButtons___KzJp {
  position: relative;
}

.RadioButtons_radioButtonItemsWrapper__MkD7k {
  display: flex;
  flex-flow: row nowrap;
}

.RadioButtons_radioButtonItem__ux_rW {
  position: relative;
  margin-right: var(--spacer-28);
}

.RadioButtons_radioButtonInner__TrHlG {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  white-space: nowrap;
}

.RadioButtons_radioButtonIcon__ih70y {
  position: relative;
  width: 2.4rem;
  height: 2.4rem;
  border: 1px solid var(--color-grey-60);
  border-radius: 1.2rem;
  margin-right: var(--spacer-12);
}

.RadioButtons_isActive__jF43n .RadioButtons_radioButtonIcon__ih70y::after {
      position: absolute;
      top: calc(50% - 0.5rem);
      left: calc(50% - 0.5rem);
      width: 1rem;
      height: 1rem;
      border-radius: 0.5rem;
      background: var(--color-accent);
      content: '';
    }

.RadioButtons_radioButtonTitle__j2ltU,
.RadioButtons_radioButtonSubtitle__g5iTZ {
}

.RadioButtons_radioButtonTitle__j2ltU {
  color: var(--color-grey-100);
}

.RadioButtons_radioButtonSubtitle__g5iTZ {
  padding: 0.1rem 1.2rem;
  padding-top: 0.3rem;
  border: 1px solid var(--color-accent);
  border-radius: 10rem;
  margin-left: var(--spacer-8);
  background-color: var(--color-accent);
  color: var(--grey-brand-grey-00, #000);
  font-family: var(--font-secondary);
  font-size: 0.9rem;
  font-weight: 100;
  text-transform: uppercase;
}

.PricingCard_pricingCard__iX2Cv {
  position: relative;
  display: flex;
  flex: 1 1 100%;
  flex-direction: column;
  padding: var(--spacer-24);
  border: 1px solid rgb(157 157 255 / 10%);
  border-radius: var(--border-radius-10);
  -webkit-backdrop-filter: blur(var(--card-background-blur, 75px));
          backdrop-filter: blur(var(--card-background-blur, 75px));
  background: #10101780;
  box-shadow:
    12px 12px 50px 0 #181826 inset,
    84.68px 149.711px 80px 0 rgb(0 0 0 / 31%),
    54.885px 97.035px 46.852px 0 rgb(0 0 0 / 26%),
    32.618px 57.666px 25.481px 0 rgb(0 0 0 / 23%),
    16.936px 29.942px 13px 0 rgb(0 0 0 / 20%),
    6.9px 12.199px 6.519px 0 rgb(0 0 0 / 18%),
    1.568px 2.772px 3.148px 0 rgb(0 0 0 / 20%);
  scroll-snap-align: start;
}

  @media (min-width: 768px) {.PricingCard_pricingCard__iX2Cv {
    flex: 1 1
}
  }

  @media (min-width: 1240px) {.PricingCard_pricingCard__iX2Cv {
    flex: 1 1
}
  }

/* 4 columns becomes 2x2 on medium breakpoint */

@media (min-width: 768px) {

.PricingCard_pricingCardColumns4__WxrbD {
    flex: 1 1 calc(50% - var(--grid-gutter-size))
}
  }

@media (min-width: 1240px) {

.PricingCard_pricingCardColumns4__WxrbD {
    flex: 1 1
}
  }

.PricingCard_highlightedCard__pl_81 {
  border: 1px solid rgb(157 157 255 / 50%);
  box-shadow:
    0 4px 74px 30px rgb(97 153 246 / 10%) inset,
    84.68px 149.711px 80px 0 rgb(0 0 0 / 31%),
    54.885px 97.035px 46.852px 0 rgb(0 0 0 / 26%),
    32.618px 57.666px 25.481px 0 rgb(0 0 0 / 23%),
    16.936px 29.942px 13px 0 rgb(0 0 0 / 20%),
    6.9px 12.199px 6.519px 0 rgb(0 0 0 / 18%),
    1.568px 2.772px 3.148px 0 rgb(0 0 0 / 20%);
}

.PricingCard_ctaRow__UomTH {
  display: flex;
  flex-direction: row;
}

@media (min-width: 768px) {

.PricingCard_ctaRow__UomTH {
    flex-direction: column
}
  }

.PricingCard_pricingCardVariantReduced__75Dmz .PricingCard_ctaRow__UomTH {
  padding-top: var(--spacer-40);
  margin-top: auto;
}

.PricingCard_cardTitle__ukAfC,
.PricingCard_cardEyebrow__WHDag,
.PricingCard_cardContentWrapper__NsZp9 {
  position: relative;
}

.PricingCard_cardIntro__UyLkW {
  margin-bottom: 0;
}

@media (min-width: 768px) {

.PricingCard_cardIntro__UyLkW {
    margin-bottom: var(--spacer-40)
}
  }

.PricingCard_cardIntro__UyLkW[data-variant='mini'] {
    margin-bottom: 0;
  }

@media (min-width: 768px) {

.PricingCard_cardIntro__UyLkW[data-variant='mini'] {
      margin-bottom: var(--spacer-24)
  }
    }

.PricingCard_cardEyebrow__WHDag {
  height: 1lh;
  margin-bottom: var(--spacer-8);
  color: var(--color-accent);
}

@media (min-width: 768px) {

.PricingCard_cardEyebrow__WHDag {
    margin-bottom: var(--spacer-16)
}
  }

.PricingCard_cardTitle__ukAfC {
  margin-right: var(--spacer-8);
  color: var(--grey-brand-grey-100, #fcfcfc);
}

.PricingCard_cardPriceWrapper__PO_Rt {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  margin-bottom: var(--spacer-6);
}

@media (min-width: 768px) {

.PricingCard_cardPriceContext__B7ybS {
    height: 100%
}
  }

.PricingCard_cardPriceContextLine__areXF {
  position: relative;
  display: block;
  min-height: 1lh;
  color: var(--alpha-brand-alpha-light-50, rgb(234 234 255 / 50%));
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 1.5;
}

.PricingCard_cardPriceContextLine__areXF:last-of-type {
    margin-top: -0.2rem;
  }

@media (min-width: 768px) {

.PricingCard_cardPriceContextLine__areXF:last-of-type {
      margin-top: calc(var(--spacer-4)*-1)
  }
    }

.PricingCard_cardSecondaryTitle__8BZDs {
  margin-right: var(--spacer-8);
  color: var(--alpha-brand-alpha-light-50, rgb(234 234 255 / 50%));
}

.PricingCard_cardPrice__qSEPp {
  margin-right: var(--spacer-8);
  color: var(--alpha-brand-alpha-light-50, rgb(234 234 255 / 50%));
}

.PricingCard_cardDescription__l9bxj {
  padding-top: var(--spacer-24);
  padding-bottom: var(--spacer-24);
  border-top: 1px solid var(--color-alpha-hawkes-20);
  margin-top: var(--spacer-24);
  color: var(--alpha-brand-alpha-light-70, rgb(233 233 255 / 70%));
}

.PricingCard_pricingCardVariantReduced__75Dmz .PricingCard_cardDescription__l9bxj {
  border-bottom: 1px solid var(--color-alpha-hawkes-20);
}

@media (min-width: 768px) {

.PricingCard_cardDescriptionContent__kAm3k {
    min-height: 3lh
}
  }

.PricingCard_cardTopBullets__k_fkg {
  position: relative;
  width: 100%;
}

.PricingCard_cardTopBullets__k_fkg,
.PricingCard_cardTopDescription__G04eD {
  margin-bottom: var(--spacer-32);
}

@media (min-width: 768px) {

.PricingCard_cardTopBullets__k_fkg,
.PricingCard_cardTopDescription__G04eD {
    margin-bottom: var(--spacer-40)
}
  }

.PricingCard_cardTopBulletItem__Fs6xK {
  position: relative;
  width: 100%;
  margin-bottom: var(--spacer-6);
}

.PricingCard_cardTopBulletItem__Fs6xK:last-child {
    margin-bottom: 0;
  }

.PricingCard_cardTopDescription__G04eD,
.PricingCard_cardTopBulletText__YXuoD {
}

.PricingCard_cardDetails__ARgHb {
  transition: height var(--duration-300ms) var(--ease-basic-butter);
}

.PricingCard_cardDetailsTitle__qCP99 {
  padding-top: var(--spacer-32);
  color: var(--grey-brand-grey-100, #fcfcfc);
}

@media (min-width: 768px) {

.PricingCard_cardDetailsTitle__qCP99 {
    padding-top: 0
}
  }

.PricingCard_cardDetailsTitle__qCP99,
.PricingCard_cardDetailsSpacer__x8zbv {
  margin-bottom: var(--spacer-8);
  color: var(--color-grey-97);
}

@media (min-width: 768px) {

.PricingCard_cardDetailsTitle__qCP99,
.PricingCard_cardDetailsSpacer__x8zbv {
    margin-bottom: var(--spacer-16)
}
  }

.PricingCard_cardDetailsSpacer__x8zbv {
  display: none;
}

@media (min-width: 1240px) {

.PricingCard_cardDetailsSpacer__x8zbv {
    display: block
}
  }

.PricingCard_cardDetailBullets__uxEbe {
  position: relative;
  width: 100%;
}

.PricingCard_cardDetailBulletItem__Zwtbp {
  position: relative;
  width: 100%;
  padding-right: var(--spacer-24);
  margin-bottom: var(--spacer-8);
}

@media (min-width: 768px) {

.PricingCard_cardDetailBulletItem__Zwtbp {
    padding-right: 0;
    padding-right: initial
}
  }

.PricingCard_cardDetailBulletCheck__Mkuj3 {
  position: relative;
  width: 1.2rem;
  height: auto;
  margin-right: var(--spacer-8);
}

.PricingCard_cardDetailBulletCheck__Mkuj3 * {
    fill: var(--color-grey-97);
  }

.PricingCard_cardDetailBulletSvgButton__zRjIu,
.PricingCard_topDetailBulletSvgButton__QWAGL {
  position: absolute;
  top: calc(50% - 1rem);
  right: -0.5rem;
  left: auto;
  width: 2.4rem;
  height: 2.4rem;
  text-align: right;
}

.PricingCard_cardDetailBulletSvgButton__zRjIu svg path, .PricingCard_topDetailBulletSvgButton__QWAGL svg path {
      fill: var(--alpha-brand-alpha-light-90, rgb(241 244 255 / 90%));
    }

.PricingCard_cardDetailBulletSvg__9w_5T,
.PricingCard_topDetailBulletSvg__KVnc8 {
  position: relative;
  width: 1.6rem;
  height: auto;
}

.PricingCard_cardDetailBulletTooltip__CSk2g,
.PricingCard_topDetailBulletTooltip__BIOso {
  position: absolute;
  z-index: var(--z-index-2);
  right: 1rem;
  width: 100%;
  padding: var(--spacer-12) var(--spacer-24) var(--spacer-12) var(--spacer-20);
  border: 1px solid var(--color-alpha-light-10);
  border-radius: 1rem 1rem 0;
  background: var(--color-grey-05);
  opacity: 0;
  transform: translateY(calc(-100% - 3rem));
  transition:
    opacity 0.2s var(--ease-out),
    visibility 0.2s;
  visibility: hidden;
}

.PricingCard_cardDetailBulletTooltip__CSk2g::after, .PricingCard_topDetailBulletTooltip__BIOso::after {
    position: absolute;
    width: 4rem;
    height: 12px;
    border-radius: 1rem 1rem 0;
    background: var(--color-grey-05);
    content: '';
    inset: auto 0 0 auto;
  }

[data-is-active] .PricingCard_cardDetailBulletTooltip__CSk2g, [data-is-active] .PricingCard_topDetailBulletTooltip__BIOso {
    opacity: 1;
    visibility: visible;
  }

.PricingCard_cardDetailBulletTooltipText__jyiGC,
.PricingCard_topDetailBulletTooltipText__85Ir4 {
  display: flex;
  flex: 1 1;
}

.PricingCard_cardDetailBulletTooltipTail__VHF46,
.PricingCard_topDetailBulletTooltipTail__eSRx8 {
  position: absolute;
  top: 100%;
  right: -1px;
  width: 12px;
  height: 12px;
  border: 1px solid var(--color-alpha-light-10);
  background: var(--color-grey-05);
  transform: skewY(45deg) translateY(-50%);
}

.PricingCard_cardDetailBulletText__0r0JA,
.PricingCard_topDetailBulletText__mwUKi {
  color: var(--alpha-brand-alpha-light-70, rgb(233 233 255 / 70%));
}

.PricingCard_cardDetailButton__6nmwh {
  z-index: var(--z-index-2);
  width: 100%;
}

@media (min-width: 768px) {

.PricingCard_cardDetailButton__6nmwh {
    width: 100%
}
  }

.PricingCard_viewAllFeaturesLink__Jy_rf {
  padding-top: var(--spacer-40);
  font-family: var(--font-main, sans-serif);
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 600;
  text-decoration: none;
}

@media (min-width: 768px) {

.PricingCard_viewAllFeaturesLink__Jy_rf {
    margin-top: auto
}
  }

.PricingCard_viewAllFeaturesLink__Jy_rf svg {
    width: 1.2rem;
    height: 1.2rem;
    margin-left: var(--spacer-6);
  }

@media (hover: hover) {
    .PricingCard_viewAllFeaturesLink__Jy_rf:hover {
      text-decoration: underline;
    }
  }

.PricingOverview_pricingOverview__qEvba {
  position: relative;
  z-index: var(--z-index-2);

  /* Adding spacer 48 which is equal to the banner height, always visible on pricing page */
  padding-top: calc(var(--announcement-banner-height) + var(--nav-height));
  padding-bottom: var(--spacer-48);
  opacity: 1;
}

  @media (min-width: 768px) {.PricingOverview_pricingOverview__qEvba {
    padding-bottom: var(--spacer-96)
}
  }

  @media (min-width: 1240px) {.PricingOverview_pricingOverview__qEvba {
    padding-top: calc(var(--announcement-banner-height) + var(--nav-height) + var(--spacer-124))
}
  }

  /* Hero Text Content */

  .PricingOverview_pricingOverview__qEvba .PricingOverview_content__XwKQf {
    margin-bottom: var(--spacer-24);
  }

  @media (min-width: 768px) {

  .PricingOverview_pricingOverview__qEvba .PricingOverview_content__XwKQf {
      margin-bottom: var(--spacer-16)
  }
    }

  @media (min-width: 1240px) {

  .PricingOverview_pricingOverview__qEvba .PricingOverview_content__XwKQf {
      margin-bottom: var(--spacer-32)
  }
    }

  /* Radio Buttons */

  .PricingOverview_pricingOverview__qEvba .PricingOverview_radioButtons__UKv2u {
    margin-bottom: var(--spacer-40);
    grid-column: start / end;
  }

  /* Card Wrappers */

  .PricingOverview_pricingOverview__qEvba .PricingOverview_pricingCardsWrapper__BzM5_,
  .PricingOverview_pricingOverview__qEvba .PricingOverview_customCardsWrapper__9MJeb {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    grid-column: wrapper-start / wrapper-end;
  }

  @media (min-width: 768px) {

  .PricingOverview_pricingOverview__qEvba .PricingOverview_pricingCardsWrapper__BzM5_,
  .PricingOverview_pricingOverview__qEvba .PricingOverview_customCardsWrapper__9MJeb {
      flex-flow: row nowrap;
      justify-content: flex-start;
      grid-column: start / end
  }
    }

  .PricingOverview_pricingOverview__qEvba .PricingOverview_pricingCardsWrapper__BzM5_ {
    padding: 0 var(--spacer-32);
    margin-bottom: var(--spacer-24);
    gap: var(--spacer-24) 0;
  }

  @media (min-width: 768px) {

  .PricingOverview_pricingOverview__qEvba .PricingOverview_pricingCardsWrapper__BzM5_ {
      overflow: auto;
      flex-wrap: wrap;

      /* extra padding to prevent vertical scrolling from shadows */
      padding: 0 var(--grid-computed-margin-width) var(--spacer-480);
      margin-bottom: calc(var(--spacer-480)*-1 + var(--spacer-24));
      gap: var(--grid-gutter-size);
      grid-column: wrapper-start / wrapper-end;
      scroll-padding: var(--grid-computed-margin-width) 0 0 var(--grid-computed-margin-width);
      scroll-snap-type: x mandatory;
      scrollbar-width: none
  }

      .PricingOverview_pricingOverview__qEvba .PricingOverview_pricingCardsWrapper__BzM5_::-webkit-scrollbar {
        display: none;
      }
    }

  @media (min-width: 1240px) {

  .PricingOverview_pricingOverview__qEvba .PricingOverview_pricingCardsWrapper__BzM5_ {
      overflow: visible;
      overflow: initial;
      padding: 0;
      margin-bottom: var(--spacer-24);
      grid-column: start / end
  }
    }

/*
  This component implements custom breakpoints that
  deviate from the global grid in between 768px and 1158px
  in order to preserve the 5-column table layout

  * 1240+ (lg): existing grid, desktop styles
  * 1158-1239 custom bp: existing grid, desktop styles
  * 960-1158 custom bp: 60px margin
  * 768-959 custom bp: 40px margin
  * 0-767 (sm): existing grid, mobile styles
*/
.PricingTable_wrapper__M5uBU {
  background: var(--color-grey-00);
}
.PricingTable_pricingColumn__k_ygK {
  padding: var(--spacer-24) 0 0 0;
  border-bottom: 1px solid var(--color-grey-15);
  margin-right: var(--grid-gutter-size);
}
@media (min-width: 1158px) {
.PricingTable_pricingColumn__k_ygK {
    padding: 0
}
  }
.PricingTable_cardTitle__Ap7mK {
  color: var(--color-accent);
}
.PricingTable_cardPrice__Sd7xm {
  margin-bottom: var(--spacer-20);
  color: var(--color-grey-70);
}
.PricingTable_cardDetailButton__HwClc {
  padding: 1.4rem var(--spacer-16) 1.3rem;
  margin-bottom: var(--spacer-24);
}
@media (min-width: 768px) {
.PricingTable_cardDetailButton__HwClc {
    margin-bottom: var(--spacer-28)
}
  }
@media (min-width: 1158px) {
.PricingTable_cardDetailButton__HwClc {
    margin-bottom: var(--spacer-24)
}
  }
.PricingTable_pricingCategory__Pu_bi {
  width: 100%;
}
@media (min-width: 1158px) {
.PricingTable_headingsRow__yMuQB .PricingTable_pricingColumn__k_ygK {
      padding-top: calc(var(--nav-height) + var(--spacer-20))
  }
    }
.PricingTable_checkmarkComparisonCheck__JkT8v,
.PricingTable_checkmarkComparisonNoCheck__0KtCC {
  position: relative;
  display: block;
  width: 1.8rem;
  height: 1.8rem;
}
.PricingTable_checkmarkComparisonCheck__JkT8v * {
    fill: var(--color-accent);
  }
.PricingTable_checkmarkComparisonNoCheck__0KtCC {
  width: auto;
}
.PricingTable_pricingTable__r2yuJ .PricingTable_checkmarkComparisonNoCheck__0KtCC {
  display: flex;
  align-items: center;
  color: var(--color-grey-100);
}
.PricingTable_checkmarkComparisonTitle__jhbbW {
  position: relative;
  display: inline-block;
  max-width: max(65%, 12rem);
  color: var(--color-alpha-light-50);
}
@media (min-width: 768px) {
.PricingTable_checkmarkComparisonTitle__jhbbW {
    max-width: max(50%, 14rem)
}
  }
.PricingTable_container__4QeRz {
  grid-column: wrapper-start / wrapper-end;
}
.PricingTable_header__Cb17b {
  z-index: var(--z-index-1);
  padding: var(--spacer-28) 0 0;
  border-bottom: 0;

  /* backdrop-filter: blur(15px); */
  grid-column: wrapper-start / wrapper-end;
  transform: translateY(0);
}
@media (min-width: 768px) {
.PricingTable_header__Cb17b {
    padding: var(--spacer-40) 0;
    border-bottom: 1px solid var(--color-alpha-light-20);
    grid-column: start / end
}
  }
@media (min-width: 768px) {
.PricingTable_header__Cb17b {
    padding: var(--spacer-32);
    grid-column: wrapper-start / wrapper-end
}
  }
@media (min-width: 960px) {
.PricingTable_header__Cb17b {
    padding: var(--spacer-40) 6rem
}
  }
@media (min-width: 1158px) {
.PricingTable_header__Cb17b {
    padding: var(--spacer-40) 0;
    grid-column: start / end
}
  }
.PricingTable_header__Cb17b.PricingTable_isStuck__j6Mje {
    position: sticky;
    top: 0;
    -webkit-backdrop-filter: blur(15px);
            backdrop-filter: blur(15px);
    background: var(--nav-gradient-when-unblurred);
    transition: transform 0.12s var(--ease-default);
  }
@media (min-width: 1158px) {
.PricingTable_header__Cb17b.PricingTable_isStuck__j6Mje {
      transform: translateY(0);
      transition: transform 0.3s var(--ease-default)
  }
    }
.PricingTable_header__Cb17b.PricingTable_isStuck__j6Mje.PricingTable_isDesktopNavigationShowing__tGQmD {
    transition: transform 0.12s var(--ease-default);
  }
/* TODO: sm has been deprecated (pricing table) */
@media (min-width: 768px) {
.PricingTable_header__Cb17b.PricingTable_isStuck__j6Mje.PricingTable_isDesktopNavigationShowing__tGQmD {
      transition: transform 0.3s var(--ease-default)
  }
    }
.PricingTable_heading__w4Kln {
  padding-left: var(--grid-computed-margin-width);
  grid-column: start / end;

  --subgrid-width: 1;
}
@media (min-width: 768px) {
.PricingTable_heading__w4Kln {
    padding-left: 0;
    grid-column: start / span 4
}
  }
.PricingTable_headingLockup__QVT0p {
  /* Disable the grid within lockup */
  display: block;
}
.PricingTable_desktopPlanHeadersWrapper__jeCrh {
  display: none;
}
@media (min-width: 768px) {
.PricingTable_desktopPlanHeadersWrapper__jeCrh {
    display: flex;
    flex-direction: row;
    gap: var(--grid-gutter-size, 0);
    grid-column: 5 / end;
    place-content: space-between space-evenly
}
  }
@media (min-width: 768px) {
.PricingTable_desktopPlanHeadersWrapper__jeCrh {
    gap: 1rem
}
  }
.PricingTable_desktopPlanHeaders__0vNJY {
  display: none;
}
@media (min-width: 768px) {
.PricingTable_desktopPlanHeaders__0vNJY {
    display: inline;
    display: initial;
    flex: 1 1;
    grid-column: span 2
}
  }
.PricingTable_desktopHeaderTitle__QiG3U,
.PricingTable_desktopHeaderSubtitle__vCeHi {
}
/* not quite our standard letter spacing but here we are again */
@media (min-width: 768px) {
.PricingTable_desktopHeaderTitle__QiG3U,
.PricingTable_desktopHeaderSubtitle__vCeHi {
    letter-spacing: -0.06rem
}
  }
@media (min-width: 1240px) {
.PricingTable_desktopHeaderTitle__QiG3U,
.PricingTable_desktopHeaderSubtitle__vCeHi {
    letter-spacing: -0.075rem
}
  }
.PricingTable_desktopHeaderTitle__QiG3U {
  color: var(--grey-brand-grey-100, #fcfcfc);
}
.PricingTable_desktopHeaderSubtitle__vCeHi {
  margin-top: -0.04rem;
  color: var(--alpha-brand-alpha-light-50, rgb(234 234 255 / 50%));
}
.PricingTable_planHeaders__oNCos {
  padding-top: var(--spacer-40);
  padding-bottom: 0;
  grid-column: start / end;
}
.PricingTable_isStuck__j6Mje .PricingTable_planHeaders__oNCos {
    padding-bottom: var(--spacer-40);
  }
@media (min-width: 768px) {
.PricingTable_planHeaders__oNCos {
    display: none
}
  }
.PricingTable_planInner__PHuxn {
  display: flex;
  width: auto;
  flex-direction: row;
  padding: 0;
  grid-column: start / end;
  overflow-y: scroll;
}
@media (min-width: 768px) {
.PricingTable_planInner__PHuxn {
    padding: var(--spacer-10)
}
  }
.PricingTable_tableBody__KxpO7 {
  padding: 0;
  grid-column: wrapper-start / wrapper-end;
}
@media (min-width: 768px) {
.PricingTable_tableBody__KxpO7 {
    padding: var(--spacer-40)
}
  }
@media (min-width: 960px) {
.PricingTable_tableBody__KxpO7 {
    padding: var(--spacer-40) 6rem
}
  }
@media (min-width: 1158px) {
.PricingTable_tableBody__KxpO7 {
    padding: 0;
    grid-column: start / end
}
  }
.PricingTable_pricingPlanButton__RYQmE {
  width: 205px;
  height: var(--spacer-48);
  margin-top: 0;
  -webkit-backdrop-filter: blur(15px);
          backdrop-filter: blur(15px);
  opacity: 0.4;
  transition: var(--duration-500ms) var(--ease-basic-butter);
}
.PricingTable_pricingPlanButton__RYQmE:not(:last-of-type) {
    margin-right: var(--spacer-10);
  }
@media (min-width: 0px) {
.PricingTable_pricingPlanButton__RYQmE {
    /* rm border on mobile */
}
    .PricingTable_pricingPlanButton__RYQmE::after {
      display: none;
    }
  }
/* note: we are doing some funky stuff here since the live copy occupies the entire two columns by itself on smaller end of medium and does not leave room for horizontal padding on the button. if instead we specify a horizontal padding we break the grid as the button becomes wider than 2 colums. */
@media (min-width: 768px) {
.PricingTable_pricingPlanButton__RYQmE {
    width: 100%;
    height: auto;
    padding: 1.45rem 0 1.5rem;
    margin-top: var(--spacer-12);
    margin-right: 0;
    opacity: 1
}

    @media (min-width: 768px) {
.PricingTable_pricingPlanButton__RYQmE {
      padding: 1rem 0
}
    }

    .PricingTable_pricingPlanButton__RYQmE::after {
      display: block;
    }
  }
@media (min-width: 768px) {
.PricingTable_pricingPlanButton__RYQmE {
    font-size: 1.2rem
}
  }
@media (min-width: 1240px) {
.PricingTable_pricingPlanButton__RYQmE {
    padding: 1.45rem 3.7rem 1.5rem
}
  }
.PricingTable_pricingPlanButton__RYQmE.PricingTable_selectedPlan__DdAAn {
    opacity: 1;
  }
.PricingTable_pricingPlanTitle__sIu0t {
  margin-right: var(--spacer-6);
  color: var(--color-cobalt);
}
.PricingTable_categoryTitle__A6qtA {

  /* override AccordionItem default styling - flip caret to the left */
  flex-direction: row-reverse;
  justify-content: flex-end;
  padding-bottom: 1rem;
  margin-top: var(--spacer-48);
  color: var(--color-grey-100);
  grid-column: start / end;
}
.PricingTable_categoryTitle__A6qtA svg {
    width: 1rem;
    height: 1rem;
    margin-right: var(--spacer-12);
    margin-left: 0;
  }
.PricingTable_categoryTitle__A6qtA svg path {
      fill: var(--color-grey-100);
    }
.PricingTable_categoryRow__YG9au {
  height: auto;
  padding: 0;
  border-bottom: 1px solid var(--color-grey-20);
  margin: 0 var(--grid-margin-size);
  grid-column: start / end;
}
@media (min-width: 768px) {
.PricingTable_categoryRow__YG9au {
    margin: 0
}
  }
.PricingTable_featureComparisonTitle__jfm7i {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.PricingTable_featureComparisonTitle__jfm7i,
.PricingTable_featureComparisonSubtitle__H4RYI {
}
.PricingTable_featureTitle__ksNjC {
  position: relative;
  padding: var(--spacer-16) 0;
  grid-column: start / end;
}
@media (min-width: 768px) {
.PricingTable_featureTitle__ksNjC {
    grid-column: start / span 4
}
  }
.PricingTable_features__8uRhw {
  /* override AccordionItem content default styling */
  padding-right: 0;
  grid-column: start / end;
}
@media (min-width: 768px) {
.PricingTable_features__8uRhw {
    padding-right: 0
}
  }
.PricingTable_features__8uRhw > :first-child {
    padding-top: 0;
  }
.PricingTable_featureRow__QJQRU {
  border-bottom: 1px solid var(--color-grey-20);
  grid-column: start / end;
}
.PricingTable_featureRow__QJQRU:last-of-type {
    border-bottom: 0;
  }
.PricingTable_mobileActiveValue__x9zvj {
  display: inline;
  display: initial;
  margin-left: var(--spacer-4);
  text-align: right;
}
@media (min-width: 768px) {
.PricingTable_mobileActiveValue__x9zvj {
    display: none
}
  }
.PricingTable_featureLimitsWrapper__WJucA {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--grid-gutter-size, 0);
  grid-column: start / end;
  place-content: space-between space-evenly;
}
@media (min-width: 768px) {
.PricingTable_featureLimitsWrapper__WJucA {
    grid-column: 5 / end
}
  }
@media (min-width: 768px) {
.PricingTable_featureLimitsWrapper__WJucA {
    gap: 1rem
}
  }
.PricingTable_featureLimit__atY0q {
  display: none;

  /* grid-column: span 2; */
  flex: 1 1;
  padding: var(--spacer-16) 0;
}
@media (min-width: 768px) {
.PricingTable_featureLimit__atY0q {
    display: inline;
    display: initial
}
  }
.PricingTable_sliderContainer__g2JCP {
  display: flex;
  min-height: auto;
  grid-column: start / end;

  /* we have to manually adjust the height of the slider container when accordions collapse.
    The transition here should match the one in AccordionItem.
  */
  transition: height var(--duration-500ms) var(--ease-basic-butter);
}
@media (min-width: 768px) {
.PricingTable_sliderContainer__g2JCP {
    display: none
}
  }
.PricingTable_slide__VJ63Z {
  width: 100%;
  min-height: auto;
  flex-shrink: 0;
}
.PricingTable_headerSlider__xH3AX {
  position: relative;
  display: flex;
  overflow: hidden;
  flex-direction: row;
  align-content: flex-start;
  touch-action: pan-y;
}
.PricingTable_headerSlide__JTyDh {
  overflow: visible;
}
.PricingTable_stickyCtaContainer__4y8PJ {
  position: fixed;
  z-index: var(--z-index-1);
  bottom: 0;
  display: flex;
  width: 100vw;
  height: var(--element-height);
  flex-direction: row;
  padding: var(--spacer-24) var(--grid-margin-size);
  border: 0.1rem solid var(--color-alpha-light-10);
  border-radius: var(--border-radius-20) var(--border-radius-20) 0 0;
  background: var(--color-grey-08);
  transform: translateY(var(--element-height));
  transition: transform var(--duration-500ms) var(--ease-basic-butter);

  --element-height: 8.8rem;
}
@media (min-width: 768px) {
.PricingTable_stickyCtaContainer__4y8PJ {
    display: none
}
  }
.PricingTable_stickyCtaContainer__4y8PJ.PricingTable_sticky__C4Lf8 {
    transform: none;
  }
.PricingTable_stickyCta__ZrvN6 {
  width: 100%;
}
.PricingTable_stickyCta__ZrvN6 > span {
    width: 100%;
  }
.PricingTable_stickyCtaSlider__sCwue {
  position: relative;
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 100%;
  min-height: 1.5rem;
  align-content: flex-start;
}
.PricingTable_ctaSlide__2k3Ax {
  text-align: center;
}
.PricingTable_desktopCategory__5fiZr {
  display: none;
}
@media (min-width: 768px) {
.PricingTable_desktopCategory__5fiZr {
    display: inline;
    display: initial
}
  }
.PricingTable_disclaimerContainer__eXkku {
  display: flex;
  flex-direction: column;
  padding-top: var(--spacer-48);
  grid-column: start / end;
}
@media (max-width: 767.98px) {
.PricingTable_disclaimerContainer__eXkku {
    margin: 0 var(--grid-margin-size)
}
  }
.PricingTable_disclaimer__WP7p3 {
  padding-top: var(--spacer-4);
  color: var(--alpha-brand-alpha-light-50, rgb(234 234 255 / 50%));
}

.QuoteInteractiveLg_quoteInteractiveLg__dWkwz {
  position: relative;
}

.QuoteInteractiveLg_dummy___A20Y {
  position: absolute;
  width: 100%;
  align-self: center;
  grid-column: start / end;
  pointer-events: none;
}

.QuoteInteractiveLg_titles__KVrDn {
  color: var(--color-grey-100);
}

.QuoteInteractiveLg_titles__KVrDn + .QuoteInteractiveLg_accents__uH_6Y {
  margin-top: var(--spacer-8);
}

.QuoteInteractiveLg_accents__uH_6Y {
}

.QuoteInteractiveLg_eyebrow__RpoJK {
  display: none;
}

.QuoteInteractiveLg_content__ifTxP {
  display: block; /* cancel Subgrid */
  padding-right: calc(var(--grid-computed-column-width) + var(--grid-gutter-size));
  grid-column: 3 / span 4;
}

.QuoteInteractiveLg_quoteInteractiveWrapper__cn7wk {
  position: relative;
  grid-column: 7 / end;
}

.QuoteInteractiveLg_quoteInteractive__qcG_X .QuoteInteractiveLg_quoteInteractive__qcG_X {
    position: relative;

    --interactive-quotes-title-color: var(--color-grey-15);
    cursor: default;
  }

.QuoteInteractiveLg_quoteInteractive__qcG_X .QuoteInteractiveLg_quoteInteractive__qcG_X:hover {
      --interactive-quotes-title-color: var(--color-grey-100);
    }

.QuoteInteractiveLg_quoteInteractiveTitleWrapper__YyDsi {
  width: 100%;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

.QuoteInteractiveLg_quoteInteractiveTitle__fKIXx {
  color: var(--interactive-quotes-title-color);
  text-align: left;
  transition: color var(--duration-500ms) var(--ease-out);
}

.QuoteInteractiveLg_quoteInteractiveCardWrapper__Lq0Qh {
  position: absolute;
  z-index: var(--z-index-2);
  top: 50%;
  left: 0;
  pointer-events: none;
  transform: translate3d(var(--mouse-x), var(--mouse-y), 0);
  transition: transform var(--ease-out) var(--duration-300ms);
}

.QuoteInteractiveLg_quoteInteractiveGlowWrapper__XCPPR {
  opacity: var(--hover-progress, 0);
  transform: translate(calc(15%*var(--hover-progress)), calc(-50% + var(--translate-y, 0)*1px));
  transform-origin: center center;
}

.QuoteInteractiveLg_quoteInteractiveCard__PZFil {
  position: relative;
  display: flex;
  overflow: hidden;
  width: var(--expanded-width, 1080px);
  align-items: center;
  justify-content: center;
  padding: 0;
  border-radius: var(--border-radius-12);
  margin: 0;
  aspect-ratio: 1 / 0.51;
  transform-origin: center center;
}

.QuoteInteractiveLg_hasInteracted__H0Ueo .QuoteInteractiveLg_quoteInteractiveCard__PZFil {
    width: 30vw; /* 433/1440 */
    max-width: 50rem;
    transform: scale(1, var(--scale-y, 1));
  }

.QuoteInteractiveLg_hasInteracted__H0Ueo .QuoteInteractiveLg_quoteInteractiveCard__PZFil .QuoteInteractiveLg_quoteInteractiveMedia__eOnen {
      transform: scale(1, var(--inner-scale-y, 1));
    }

.QuoteInteractiveLg_hasInteracted__H0Ueo .QuoteInteractiveLg_quoteInteractiveCard__PZFil .QuoteInteractiveLg_moreButton__fJHpT {
      transform: scale(1, var(--inner-scale-y, 1));
    }

.QuoteInteractiveLg_quoteInteractiveCard__PZFil .QuoteInteractiveLg_quoteInteractiveMedia__eOnen,
  .QuoteInteractiveLg_quoteInteractiveCard__PZFil .QuoteInteractiveLg_quoteInteractiveLogo__KIyo5 {
    pointer-events: none;
    transform-origin: center center;
    transition: none;
  }

.QuoteInteractiveLg_quoteInteractiveCard__PZFil .QuoteInteractiveLg_quoteInteractiveMedia__eOnen {
    position: relative;
    width: 100%;
    aspect-ratio: 1 / 0.51;
  }

.QuoteInteractiveLg_quoteInteractiveCard__PZFil .QuoteInteractiveLg_quoteInteractiveLogo__KIyo5 {
    position: absolute;
    top: auto;
    bottom: var(--spacer-24);
    left: var(--spacer-28);

    --graphic-scale: 1/2;
  }

.QuoteInteractiveLg_quoteInteractiveCard__PZFil .QuoteInteractiveLg_quoteInteractiveMediaImage__eq54c {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }

.QuoteInteractiveLg_quoteInteractiveCard__PZFil .QuoteInteractiveLg_moreButton__fJHpT {
    position: absolute;
    top: var(--spacer-16);
    right: var(--spacer-16);
    display: flex;
    width: 4rem;
    height: 4rem;
    align-items: center;
    justify-content: center;
    border: 0.1rem solid var(--color-alpha-light-15);
    border-radius: 50%;
    pointer-events: none;
  }

.QuoteInteractiveLg_quoteInteractiveCard__PZFil .QuoteInteractiveLg_moreButton__fJHpT .QuoteInteractiveLg_buttonIcon__CgPmM {
      width: 1rem;
      height: 1rem;
    }

.QuoteInteractiveLg_quoteInteractiveGradients__P4JTf {
  position: absolute;
  top: 0;
  left: 0;
  background: var(--interactive-quotes-gradient);
  -webkit-background-clip: text;
          background-clip: text;
  opacity: 1;
  pointer-events: none;
  -webkit-text-fill-color: transparent;
  transition: opacity var(--duration-500ms) ease-in-out;
}

.QuoteInteractiveLg_quoteInteractiveGradients__P4JTf.QuoteInteractiveLg_hidden__M6U46 {
    opacity: 0;
  }

.QuoteInteractiveLg_quoteInteractiveGradients__P4JTf .QuoteInteractiveLg_quoteInteractive__qcG_X {
    /* Unset so that it doesn't prevent the gradient to apply */
    position: static;
    position: initial;
  }

.QuoteInteractiveLgModal_modalWrapper__CPFGb {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  grid-column: unset;
}

.ModalCarouselArrows_modalCarouselArrows__TZlFz {
  position: absolute;
  z-index: var(--z-index-3);
  bottom: 0;
  width: 100%;
  grid-column: 2 / span 8;
}

.ModalCarouselArrows_innerWrapper__Q2Bpu {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: var(--spacer-32) 0 0;
  border-top: 0.1rem solid var(--arrow-keyline-color, var(--color-grey-50));
  gap: var(--spacer-24);
}

.ModalCarouselArrows_innerWrapper__Q2Bpu::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 0.1rem;
    background-color: var(--color-grey-50);
    content: '';
    transform: scaleX(var(--controls-progress, 0));
    transform-origin: center left;
  }

.ModalCarouselArrows_controlsButton__W0QVZ {
  display: flex;
  align-items: center;
  padding: 0;
  cursor: pointer;
  transition: opacity var(--duration-300ms) var(--ease-basic-butter);

  --icon-size: 3.5rem;
}

.ModalCarouselArrows_controlsButton__W0QVZ:hover {
    --button-hover-progress: 1;
  }

.ModalCarouselArrows_controlsButton__W0QVZ .ModalCarouselArrows_controlsButtonTitle__pvoVx {
    color: var(--color-grey-100);
  }

.ModalCarouselArrows_controlsButton__W0QVZ .ModalCarouselArrows_controlsDash__wOuJq {
    padding: 0 var(--spacer-8);
  }

.ModalCarouselArrows_controlsButton__W0QVZ .ModalCarouselArrows_controlsButtonArrow__1la55 {
    display: inline-block;
    width: 1.25rem;
    margin-left: var(--spacer-8);
  }

.ModalCarouselArrows_controlsButton__W0QVZ .ModalCarouselArrows_controlsButtonArrow__1la55 path {
      fill: var(--color-grey-100);
    }

.ModalCarouselArrows_controlsButton__W0QVZ:first-child .ModalCarouselArrows_controlsButtonArrow__1la55 {
      margin-right: var(--spacer-8);
      margin-left: 0;
    }

.ModalCarouselArrows_controlsButton__W0QVZ:disabled {
    opacity: 0.1;
    pointer-events: none;
  }

.QuoteInteractiveLgModalContent_dummy__yyqZ7 {
  position: relative;
  height: 0;
  align-self: center;
  padding-top: 51%; /* ratio */
  grid-column: start / end;
  pointer-events: none;
}

.QuoteInteractiveLgModalContent_modalBackground__F_Wtl {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.QuoteInteractiveLgModalContent_modalShadow__JcQVk {
  position: absolute;
  top: 0;
  left: 0;
  width: var(--width, 100vw);
  height: var(--height, 51vw); /* 0.51 card ratio */
  opacity: 0;
}

.QuoteInteractiveLgModalContent_modalContent__WNznS {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  width: var(--width, 100vw);
  height: var(--height, 51vw); /* 0.51 card ratio */
  border-radius: var(--card-border-radius);
  grid-column: unset; /* for some reason it mess with top/left positioning */
  opacity: 0;
  transform-origin: top left;
}

/* important for positioning at exact spot where the card was */

.QuoteInteractiveLgModalContent_modalContent__WNznS .QuoteInteractiveLgModalContent_modalCarouselArrows__yc8ZA {
    padding-right: var(--spacer-48);
    padding-bottom: var(--spacer-28);
    padding-left: var(--spacer-48);
    grid-column: start / end;
  }

.QuoteInteractiveLgModalContent_carousel__pK3e0 {
  position: relative;
  display: flex;
  height: var(--height);
  align-items: center;
  justify-content: flex-start;
  grid-column: start / end;
  transform: translateX(calc(var(--carousel-index)*-100%));
  transition: transform var(--duration-300ms) var(--ease-basic-butter);
}

/* Carousel items */

.QuoteInteractiveLgModalContent_carouselItem__hR7mq {
  position: relative;
  min-width: var(--width);
  height: var(--height);
  padding: 0 var(--spacer-48) calc(8rem + var(--spacer-32)) var(--spacer-48);

  --title-4-pothole-column-start: col 1;
}

/* shadow for readability */

.QuoteInteractiveLgModalContent_carouselItem__hR7mq::before {
    position: absolute;
    z-index: var(--z-index-1);
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, var(--transparent) -20%, var(--color-alpha-black-90) 108%);
    content: '';
    opacity: var(--shadow-opacity, 0);
    pointer-events: none;
  }

.QuoteInteractiveLgModalContent_quoteCardMedia__5PQM8 {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  aspect-ratio: 1 / 0.51;
  transform: translate(-50%, -50%);
}

.QuoteInteractiveLgModalContent_quoteCardMedia__5PQM8 .QuoteInteractiveLgModalContent_quoteCardMediaImg__IT3O_ {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }

.QuoteInteractiveLgModalContent_quoteCardContent__6vYuU,
.QuoteInteractiveLgModalContent_quoteCardTitle__Vg_yR {
  z-index: var(--z-index-2);
}

.QuoteInteractiveLgModalContent_quoteCardContent__6vYuU {
  position: relative;
  height: 100%;
  flex-direction: column;
  padding: var(--spacer-48) 0 0;
  grid-column: 1 / span 7;
  opacity: var(--item-progress);
  transform: translateX(calc(2rem - var(--item-progress)*2rem));
}

.QuoteInteractiveLgModalContent_quoteCardTitle__Vg_yR {
  display: block;
  grid-column: start / end;
}

.QuoteInteractiveLgModalContent_quoteCardText__HwbKE {
  --title-column-start: start;
  height: auto;
  grid-template-rows: initial;
  place-self: end;
}

.QuoteInteractiveLgModalContent_em__alx4P,
.QuoteInteractiveLgModalContent_emWithDash__5pkp2 {
  color: var(--color-alpha-light-50);
}

.QuoteInteractiveLgModalContent_em__alx4P::before, .QuoteInteractiveLgModalContent_emWithDash__5pkp2::before {
    color: var(--color-grey-95);
  }

.QuoteInteractiveLgModalContent_closeButton__fjFct {
  position: absolute;
  top: var(--spacer-40);
  right: var(--spacer-40);
  display: flex;
  width: 4rem;
  height: 4rem;
  align-items: center;
  justify-content: center;
  padding: 0;
  cursor: pointer;
  transform-origin: top right;
}

.QuoteInteractiveLgModalContent_closeButton__fjFct::before {
    position: absolute;
    border: 0.1rem solid var(--color-alpha-light-15);
    border-radius: 50%;
    content: '';
    inset: 0;
    transition: transform var(--duration-300ms) var(--ease-basic-butter);
  }

.QuoteInteractiveLgModalContent_closeButton__fjFct:hover::before {
      transform: scale(1.2);
    }

.QuoteInteractiveLgModalContent_closeButton__fjFct .QuoteInteractiveLgModalContent_buttonIcon__3kx4Y {
    width: 1rem;
    height: 1rem;
    transform: rotate(calc(var(--rotate, 0)*1deg));
    transform-origin: center center;
  }

.MultiImageMediaContainer_multiImageMediaContainer__xcPl5 {
  position: relative;
  z-index: var(--z-index-2);
  max-height: 80rem;
  align-items: flex-start;
  aspect-ratio: 0.75;
  grid-column: wrapper-start / wrapper-end;
}

  @media (min-width: 768px) {.MultiImageMediaContainer_multiImageMediaContainer__xcPl5 {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    aspect-ratio: 0.7;
    grid-column: var(--multi-media-column-start) / span var(--multi-media-column-span);
    grid-row: 1;
    place-content: center
}
  }

  @media (min-width: 1240px) {.MultiImageMediaContainer_multiImageMediaContainer__xcPl5 {
    aspect-ratio: 0.93
}
  }

  .MultiImageMediaContainer_multiImageMediaContainer__xcPl5 .MultiImageMediaContainer_mediaForegroundElementWrapper__n6k6f {
    position: relative;
  }

  .MultiImageMediaContainer_multiImageMediaContainer__xcPl5 .MultiImageMediaContainer_mediaForegroundElementWrapper__n6k6f .MultiImageMediaContainer_mediaForegroundElement__GO2Qa {
      height: auto;
    }

  .MultiImageMediaContainer_multiImageMediaContainer__xcPl5 .MultiImageMediaContainer_mediaBackgroundContainer__lyNxl {
    width: 100%;
    height: 100%;
    grid-column: 2 / end;
  }

  .MultiImageMediaContainer_multiImageMediaContainer__xcPl5 .MultiImageMediaContainer_mediaBackgroundContainer__lyNxl .MultiImageMediaContainer_shadow__SvCxG {
      min-width: 100%;
      height: 100%;
    }

  .MultiImageMediaContainer_multiImageMediaContainer__xcPl5 .MultiImageMediaContainer_mediaBackgroundContainer__lyNxl .MultiImageMediaContainer_innerShadow__IBZ2X {
      bottom: 21px;
    }

  .MultiImageMediaContainer_multiImageMediaContainer__xcPl5 .MultiImageMediaContainer_mediaBackgroundContainer__lyNxl .MultiImageMediaContainer_glassWrapper__brAZK {
      min-width: 100%;
      height: 100%;
      aspect-ratio: var(--default-image-aspect-ratio);
    }

  .MultiImageMediaContainer_multiImageMediaContainer__xcPl5 .MultiImageMediaContainer_mediaBackgroundContainer__lyNxl .MultiImageMediaContainer_mediaBackgroundElement__zFebO {
      position: relative;
      min-width: 100%;
      height: 100%;
      -o-object-fit: cover;
         object-fit: cover;
    }

  .MultiImageMediaContainer_multiImageMediaContainer__xcPl5 .MultiImageMediaContainer_mediaBackgroundContainer__lyNxl .MultiImageMediaContainer_mediaBackgroundElement__zFebO video {
        -o-object-fit: cover;
           object-fit: cover;
      }

  .MultiImageMediaContainer_multiImageMediaContainer__xcPl5 .MultiImageMediaContainer_mediaForegroundContainer__ta7z0 {
    position: absolute;
    z-index: 2;
    display: block;
    grid-column: start / end;
  }

  .MultiImageMediaContainer_multiImageMediaContainer__xcPl5 .MultiImageMediaContainer_glassWrapperContent__wbOFd {
    width: 100%;
    height: 100%;
  }

  .MultiImageMediaContainer_multiImageMediaContainer__xcPl5.MultiImageMediaContainer_alignLeft__Dtqhe .MultiImageMediaContainer_mediaBackgroundElement__zFebO {
      -o-object-position: left;
         object-position: left;
    }

  .MultiImageMediaContainer_multiImageMediaContainer__xcPl5.MultiImageMediaContainer_alignLeft__Dtqhe .MultiImageMediaContainer_innerShadow__IBZ2X {
      left: var(--background-offset);
    }

  .MultiImageMediaContainer_multiImageMediaContainer__xcPl5.MultiImageMediaContainer_alignLeft__Dtqhe .MultiImageMediaContainer_mediaBackgroundContainer__lyNxl .MultiImageMediaContainer_glassWrapper__brAZK {
      left: var(--background-offset);
    }

  .MultiImageMediaContainer_multiImageMediaContainer__xcPl5.MultiImageMediaContainer_alignLeft__Dtqhe .MultiImageMediaContainer_mediaBackgroundContainer__lyNxl .MultiImageMediaContainer_glassWrapper__brAZK video {
        -o-object-position: left;
           object-position: left;
      }

  @media (min-width: 768px) {

  .MultiImageMediaContainer_multiImageMediaContainer__xcPl5.MultiImageMediaContainer_alignLeft__Dtqhe .MultiImageMediaContainer_mediaForegroundContainer__ta7z0 {
        left: 0
    }
      }

  .MultiImageMediaContainer_multiImageMediaContainer__xcPl5.MultiImageMediaContainer_alignRight__vqYF0 .MultiImageMediaContainer_mediaBackgroundElement__zFebO {
      -o-object-position: right;
         object-position: right;
    }

  .MultiImageMediaContainer_multiImageMediaContainer__xcPl5.MultiImageMediaContainer_alignRight__vqYF0 .MultiImageMediaContainer_innerShadow__IBZ2X {
      right: var(--background-offset);
    }

  .MultiImageMediaContainer_multiImageMediaContainer__xcPl5.MultiImageMediaContainer_alignRight__vqYF0 .MultiImageMediaContainer_mediaBackgroundContainer__lyNxl .MultiImageMediaContainer_glassWrapper__brAZK {
      right: var(--background-offset);
    }

  .MultiImageMediaContainer_multiImageMediaContainer__xcPl5.MultiImageMediaContainer_alignRight__vqYF0 .MultiImageMediaContainer_mediaBackgroundContainer__lyNxl .MultiImageMediaContainer_glassWrapper__brAZK video {
        -o-object-position: right;
           object-position: right;
      }

  .MultiImageMediaContainer_multiImageMediaContainer__xcPl5.MultiImageMediaContainer_alignRight__vqYF0 .MultiImageMediaContainer_mediaForegroundContainer__ta7z0 {
      justify-self: end;
    }

  @media (min-width: 768px) {

  .MultiImageMediaContainer_multiImageMediaContainer__xcPl5.MultiImageMediaContainer_alignRight__vqYF0 .MultiImageMediaContainer_mediaForegroundContainer__ta7z0 {
        right: 0
    }
      }
.MultiImageMediaContainer_wideAspectRatioContainer__3_WNT {
  --background-offset: 26%;
}
@media (min-width: 768px) {
.MultiImageMediaContainer_wideAspectRatioContainer__3_WNT {
    --background-offset: 14%
}
  }
@media (min-width: 1240px) {
.MultiImageMediaContainer_wideAspectRatioContainer__3_WNT {
    --background-offset: 9rem
}
  }
@media (min-width: 1921px) {
.MultiImageMediaContainer_wideAspectRatioContainer__3_WNT {
    --background-offset: 9.5rem
}
  }
.MultiImageMediaContainer_wideAspectRatioContainer__3_WNT .MultiImageMediaContainer_mediaBackgroundContainer__lyNxl .MultiImageMediaContainer_glassWrapper__brAZK {
      position: absolute;
      height: 100%;
    }
.MultiImageMediaContainer_wideAspectRatioContainer__3_WNT .MultiImageMediaContainer_mediaForegroundContainer__ta7z0 {
    top: 46%;
    width: 140%;
    max-width: 58rem;
  }
@media (min-width: 768px) {
.MultiImageMediaContainer_wideAspectRatioContainer__3_WNT .MultiImageMediaContainer_mediaForegroundContainer__ta7z0 {
      top: 46%;
      width: 82%;
      margin-top: 0
  }
    }
@media (min-width: 1240px) {
.MultiImageMediaContainer_wideAspectRatioContainer__3_WNT .MultiImageMediaContainer_mediaForegroundContainer__ta7z0 {
      width: 70%
  }
    }
.MultiImageMediaContainer_narrowAspectRatioContainer__7DPRJ {
  --background-offset: 26%;
}
@media (min-width: 768px) {
.MultiImageMediaContainer_narrowAspectRatioContainer__7DPRJ {
    --background-offset: 14%;
    height: 100%
}
  }
@media (min-width: 1240px) {
.MultiImageMediaContainer_narrowAspectRatioContainer__7DPRJ {
    --background-offset: 9rem
}
  }
@media (min-width: 1921px) {
.MultiImageMediaContainer_narrowAspectRatioContainer__7DPRJ {
    --background-offset: 9.5rem
}
  }
.MultiImageMediaContainer_narrowAspectRatioContainer__7DPRJ .MultiImageMediaContainer_mediaBackgroundContainer__lyNxl {
    margin-top: 8%;
  }
.MultiImageMediaContainer_narrowAspectRatioContainer__7DPRJ .MultiImageMediaContainer_mediaBackgroundContainer__lyNxl .MultiImageMediaContainer_glassWrapper__brAZK {
      position: absolute;
      height: 95%;
    }
@media (min-width: 768px) {
.MultiImageMediaContainer_narrowAspectRatioContainer__7DPRJ .MultiImageMediaContainer_mediaBackgroundContainer__lyNxl {
      margin-top: 0
  }
    }
.MultiImageMediaContainer_narrowAspectRatioContainer__7DPRJ .MultiImageMediaContainer_mediaForegroundContainer__ta7z0 {
    width: 79%;
  }
@media (min-width: 768px) {
.MultiImageMediaContainer_narrowAspectRatioContainer__7DPRJ .MultiImageMediaContainer_mediaForegroundContainer__ta7z0 {
      display: flex;
      width: 65%;
      max-width: 36rem;
      height: 100%;
      flex-direction: column;
      justify-content: center
  }
    }
@media (min-width: 1240px) {
.MultiImageMediaContainer_narrowAspectRatioContainer__7DPRJ .MultiImageMediaContainer_mediaForegroundContainer__ta7z0 {
      width: 50%
  }
    }
/* Parallax */
.MultiImageMediaContainer_mediaForegroundContainer__ta7z0 {
  --max-parallax-y: 12%;
}
@media (min-width: 768px) {
.MultiImageMediaContainer_mediaBackgroundContainer__lyNxl,
.MultiImageMediaContainer_mediaForegroundContainer__ta7z0 {
    --translate-y: calc((var(--multi-media-container-progress)*(-1*var(--max-parallax-y) - var(--max-parallax-y)))/1 + var(--max-parallax-y));
    transform: translateY(var(--translate-y));
    transition: transform 0.5s var(--ease-mini-bounce)
}
  }

.listItemsByIndex_listItemsByIndex__PD79u {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.listItemsByIndex_listItemsByIndexItem__ET95T {
  width: 100%;
  counter-increment: nav-counter;
  list-style: none;
}

.listItemsByIndex_listItemsByIndexItemLink__Lv6pT {
  display: flex;
  width: 100%;
  align-items: baseline;
  color: var(--default-color, var(--color-grey-100));
  text-transform: none;
  transition: color var(--duration-600ms) var(--ease-out);
}

.listItemsByIndex_listItemsByIndexItemLink__Lv6pT.active {
    color: var(--active-color, var(--color-grey-100));
  }

.listItemsByIndex_listItemsByIndexItemLink__Lv6pT::before {
    margin-right: var(--spacer-6);
    color: var(--color-alpha-light-50);
    content: counter(nav-counter, decimal-leading-zero);
    transition: color var(--duration-600ms) var(--ease-out);
  }

.listItemsByIndex_listItemsByIndexItemLink__Lv6pT .listItemsByIndex_listItemsByIndexItemInner__MLJxI {
    color: inherit;
  }

@media (min-width: 768px) {

.listItemsByIndex_listItemsByIndexItemLink__Lv6pT {
    width: auto;
    width: initial
}
  }

.listItemsByIndex_listItemsByIndexItemInner__MLJxI {
  display: inline-block;
  width: 100%;
  padding-bottom: var(--row-gap, var(--spacer-12));
  text-transform: none;
  transition: transform var(--duration-600ms) var(--ease-basic-butter);
}

@media (min-width: 768px) {

.listItemsByIndex_listItemsByIndexItemInner__MLJxI {
    /* instead of using the gap property on the flexbox parent, we'll apply it as padding to the individual items so that there is a seamless transitioning when mousing over from one item to the next */
    padding-right: var(--grid-gutter-size);
    padding-bottom: var(--row-gap, var(--spacer-18))
}
  }

.SideBySideItem_sideBySide__d0KmA {
  display: grid;

  --side-by-side-radius: var(--border-radius-10);
  --shadow-radius: var(--side-by-side-radius);

  /* Single image media config */
  --single-media-column-start: start;
  --single-media-column-span: var(--grid-number-of-columns);
}

  @media (min-width: 1240px) {

  .SideBySideItem_sideBySide__d0KmA .SideBySideItem_titleContent__7NPFH {
      --lockup-column-span: 5
  }
    }

  .SideBySideItem_sideBySide__d0KmA.SideBySideItem_isStackCard__QM2Zp .SideBySideItem_titleContent__7NPFH {
      --lockup-column-span: unset;
    }

  .SideBySideItem_sideBySide__d0KmA.SideBySideItem_isStackCard__QM2Zp .SideBySideItem_mediaContainer__dlZhY {
      order: -1;
    }

  @media (min-width: 768px) {

  .SideBySideItem_sideBySide__d0KmA.SideBySideItem_isStackCard__QM2Zp .SideBySideItem_mediaContainer__dlZhY {
        order: inherit
    }
      }

  @media (min-width: 768px) {

  .SideBySideItem_sideBySide__d0KmA.SideBySideItem_isTextRight__JMcCv {
      /* Single image media config */
      --single-media-column-start: col 1;
      --single-media-column-span: calc(var(--grid-number-of-columns)/2);

      /* Multi image media config */
      --multi-media-column-start: wrapper-start;
      --multi-media-column-span: calc(var(--grid-number-of-columns)/2 + 1);

      /* Text lockup grid config */
      --lockup-grid-start: calc(var(--grid-number-of-columns)/2 + 2)
  }

      .SideBySideItem_sideBySide__d0KmA.SideBySideItem_isTextRight__JMcCv.SideBySideItem_isStackCard__QM2Zp {
        --single-media-column-start: col 1;
        --single-media-column-span: calc(var(--grid-number-of-columns)/2 + 1);
        --lockup-grid-start: calc(var(--grid-number-of-columns)/2 + 3);
      }

      .SideBySideItem_sideBySide__d0KmA.SideBySideItem_isTextRight__JMcCv.SideBySideItem_isBleed__WHqzw {
        --single-media-column-start: wrapper-start;
        --single-media-column-span: calc(var(--grid-number-of-columns)/2 + 1);
        --lockup-grid-start: calc(var(--grid-number-of-columns)/2 + 2);
      }
    }

  @media (min-width: 768px) {

  .SideBySideItem_sideBySide__d0KmA.SideBySideItem_isTextRight__JMcCv .SideBySideItem_mediaContainer__dlZhY {
        order: -1;
        justify-self: end
    }
      }

  @media (min-width: 768px) {

  .SideBySideItem_sideBySide__d0KmA.SideBySideItem_isTextLeft__dP5yc {
      /* Single image media config */
      --single-media-column-start: calc(var(--grid-number-of-columns)/2 + 2);
      --single-media-column-span: calc(var(--grid-number-of-columns)/2);

      /* Multi image media config */
      --multi-media-column-start: calc(var(--grid-number-of-columns)/2 + 2);
      --multi-media-column-span: calc(var(--grid-number-of-columns)/2 + 1);

      /* Text lockup grid config */
      --lockup-grid-start: col 1
  }

      .SideBySideItem_sideBySide__d0KmA.SideBySideItem_isTextLeft__dP5yc.SideBySideItem_isMultiImage__2mCjr {
        --lockup-grid-start: col 1;
      }

      .SideBySideItem_sideBySide__d0KmA.SideBySideItem_isTextLeft__dP5yc.SideBySideItem_isStackCard__QM2Zp {
        --single-media-column-start: calc(var(--grid-number-of-columns)/2 + 1);
        --single-media-column-span: calc(var(--grid-number-of-columns)/2);
        --lockup-grid-start: col 1;
      }

        .SideBySideItem_sideBySide__d0KmA.SideBySideItem_isTextLeft__dP5yc.SideBySideItem_isStackCard__QM2Zp .SideBySideItem_mediaContainer__dlZhY {
          order: -1;
        }

      .SideBySideItem_sideBySide__d0KmA.SideBySideItem_isTextLeft__dP5yc.SideBySideItem_isBleed__WHqzw {
        --single-media-column-start: calc(var(--grid-number-of-columns)/2 + 2);
        --single-media-column-span: calc(var(--grid-number-of-columns)/2 + 1);
        --lockup-grid-start: col 1;
      }
    }

.SideBySideItem_contentWrapper__sciF1 {
  z-index: var(--z-index-2);
  flex-flow: column;
  margin-bottom: var(--spacer-40);
  place-content: center;
  pointer-events: initial;
}

.SideBySideItem_noTitleAndContent__8nwgX .SideBySideItem_contentWrapper__sciF1 {
    margin-bottom: 0;
  }

@media (min-width: 768px) {

.SideBySideItem_contentWrapper__sciF1 {
    margin-bottom: 0;
    grid-row: 1;

    --content-wrapper-start: col 1;
    --content-wrapper-width: 4
}
  }

.SideBySideItem_content__UZ90N + .SideBySideItem_content__UZ90N {
    margin-top: var(--spacer-24);
  }

@media (min-width: 768px) {

.SideBySideItem_content__UZ90N + .SideBySideItem_content__UZ90N {
      margin-top: var(--spacer-48)
  }
    }

.SideBySideItem_touts__FcLGL {
  --touts-content-width: var(--grid-number-of-columns);
  margin-top: var(--spacer-40);
  grid-column: var(--lockup-grid-start, start) / span var(--touts-content-width);
}

.SideBySideItem_noTitleAndContent__8nwgX .SideBySideItem_touts__FcLGL {
    margin-top: 0;
  }

@media (min-width: 768px) {

.SideBySideItem_noTitleAndContent__8nwgX .SideBySideItem_touts__FcLGL {
      margin-top: 0
  }
    }

.SideBySideItem_noTitleAndContent__8nwgX.SideBySideItem_isMultiImage__2mCjr .SideBySideItem_touts__FcLGL {
    margin-top: var(--spacer-40);
  }

@media (min-width: 768px) {

.SideBySideItem_noTitleAndContent__8nwgX.SideBySideItem_isMultiImage__2mCjr .SideBySideItem_touts__FcLGL {
      margin-top: 0
  }
    }

@media (min-width: 768px) {

.SideBySideItem_touts__FcLGL {
    --touts-content-width: 3;
    margin-top: var(--spacer-48)
}
  }

@media (max-width: 767.98px) {

.SideBySideItem_toutsContainer__TOfPD {
    row-gap: var(--spacer-32)
}
  }

.SideBySideItem_mediaContainer__dlZhY {
  position: relative;
  aspect-ratio: 0.888;
}

.SideBySideItem_isMobile__51Ym5 {
  display: inline;
  display: initial;
}

@media (min-width: 768px) {

.SideBySideItem_isMobile__51Ym5 {
    display: none
}
  }

.SideBySideItem_isTablet__LRjam {
  display: none;
}

@media (min-width: 768px) {

.SideBySideItem_isTablet__LRjam {
    display: inline;
    display: initial
}
  }

.SideBySideItem_linkList__tck9l {

  --link-list-content-width: var(--grid-number-of-columns);
  margin-top: var(--spacer-40);
  grid-column: var(--lockup-grid-start, start) / span var(--link-list-content-width);
}

@media (min-width: 768px) {

.SideBySideItem_linkList__tck9l {
    margin-top: var(--spacer-48);

    --link-list-content-width: 4
}
  }

@media (hover: hover) {
      .SideBySideItem_linkList__tck9l:has(.SideBySideItem_linkListItem__j_jHV:hover) .SideBySideItem_linkListItemLink__xXZJB {
        color: var(--deactivated-color, var(--color-alpha-light-20));
      }

        .SideBySideItem_linkList__tck9l:has(.SideBySideItem_linkListItem__j_jHV:hover) .SideBySideItem_linkListItemLink__xXZJB::before {
          color: var(--deactivated-color, var(--color-alpha-light-20));
        }
  }

.SideBySideItem_linkListItem__j_jHV {
  /* stylelint-enable declaration-no-important */
}

/* Need to make sure we override the above */

/* stylelint-disable declaration-no-important */

@media (hover: hover) {
      .SideBySideItem_linkListItem__j_jHV:hover .SideBySideItem_linkListItemLink__xXZJB {
        color: var(--color-alpha-grey-100) !important;
        transition: color var(--duration-300ms) var(--ease-out) !important;
      }

        .SideBySideItem_linkListItem__j_jHV:hover .SideBySideItem_linkListItemLink__xXZJB::before {
          color: var(--color-alpha-light-50) !important;
          transition: color var(--duration-300ms) var(--ease-out) !important;
        }

      .SideBySideItem_linkListItem__j_jHV:hover .SideBySideItem_linkListItemLabel__cmdUX {
        transform: translateX(0.25rem) !important;
        transition: transform var(--duration-300ms) var(--ease-basic-butter) !important;
      }
  }

.SideBySideItem_linkListItemLink__xXZJB {
}

.SideBySideItem_linkListItemLabel__cmdUX {
}

.SingleImageMediaContainer_singleImageMediaContainer__EVKV3 {
  position: relative;
  z-index: var(--z-index-2);
  width: 100%;
  align-self: center;
  margin-bottom: var(--spacer-40);
  grid-column: var(--single-media-column-start) / span var(--single-media-column-span);
}
@media (min-width: 768px) {
.SingleImageMediaContainer_singleImageMediaContainer__EVKV3 {
    margin-bottom: 0;
    grid-row: 1
}
  }
.SingleImageMediaContainer_singleImageMediaContainer__EVKV3.SingleImageMediaContainer_hasParallax__0THT9 .SingleImageMediaContainer_media__dwRvi {
      height: calc(100% + 4rem);
      transform-origin: top;
      transition: transform var(--duration-150ms) var(--ease-out);
    }
@media (min-width: 768px) {
.SingleImageMediaContainer_singleImageMediaContainer__EVKV3.SingleImageMediaContainer_hasParallax__0THT9 .SingleImageMediaContainer_media__dwRvi {
        height: calc(100% + 8rem)
    }
      }
.SingleImageMediaContainer_singleImageMediaContainer__EVKV3 .SingleImageMediaContainer_shadow__VQ1rI {
    width: 100%;
    height: 100%;
  }
.SingleImageMediaContainer_singleImageMediaContainer__EVKV3 .SingleImageMediaContainer_mediaWrapper__0Ofgz {
    --side-by-side-progress: 0.5;
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    border-radius: var(--side-by-side-radius, var(--border-radius-10));
    pointer-events: initial;

    /* Safari fix for the border-radius */
    transform: translate3d(0, 0, 0);
  }
.SingleImageMediaContainer_hasLogo__3uOv9 .SingleImageMediaContainer_singleImageMediaContainer__EVKV3 .SingleImageMediaContainer_mediaWrapper__0Ofgz::after {
      position: absolute;
      display: block;
      border-radius: var(--side-by-side-radius, var(--border-radius-5));
      background-color: var(--color-alpha-black-20);
      content: '';
      inset: 0;
    }
.SingleImageMediaContainer_singleImageMediaContainer__EVKV3 .SingleImageMediaContainer_mediaWrapperContent__s_ItH {
    height: 100%;
  }
.SingleImageMediaContainer_singleImageMediaContainer__EVKV3 .SingleImageMediaContainer_media__dwRvi {
    position: relative;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
.SingleImageMediaContainer_logo__OWf0b {
  position: absolute;
  z-index: var(--z-index-3);
  top: auto;
  bottom: var(--spacer-32);
  left: var(--spacer-32);
  width: auto;
}
@media (min-width: 1240px) {
.SingleImageMediaContainer_logo__OWf0b {
    bottom: var(--spacer-40);
    left: var(--spacer-40)
}
  }
.SingleImageMediaContainer_logo__OWf0b * {
    fill: var(--color-grey-100);
  }
.SingleImageMediaContainer_isBleed__utTbM {
  height: var(--max-bleed-media-height);
  text-align: right;

  --max-bleed-media-height: 31.2rem;
}
@media (min-width: 768px) {
.SingleImageMediaContainer_isBleed__utTbM {
    --max-bleed-media-height: 59.2rem
}
  }
@media (min-width: 1240px) {
.SingleImageMediaContainer_isBleed__utTbM {
    --max-bleed-media-height: 76.7rem
}
  }
.SingleImageMediaContainer_isBleed__utTbM .SingleImageMediaContainer_mediaWrapper__0Ofgz {
    position: absolute;
    top: 50%;
    width: 200%;
    max-width: calc(var(--max-bleed-media-height)*var(--media-aspect-ratio));
    transform: translateY(-50%);
  }
@media (min-width: 768px) {
.SingleImageMediaContainer_isBleed__utTbM .SingleImageMediaContainer_mediaWrapper__0Ofgz {
      width: 300%
  }
    }
@media (min-width: 1240px) {
.SingleImageMediaContainer_isBleed__utTbM .SingleImageMediaContainer_mediaWrapper__0Ofgz {
      width: 200%
  }
    }
.SingleImageMediaContainer_isBleed__utTbM.SingleImageMediaContainer_alignRight__TSmCb .SingleImageMediaContainer_mediaWrapper__0Ofgz {
      right: 0;
    }
.SingleImageMediaContainer_isBleed__utTbM.SingleImageMediaContainer_alignRight__TSmCb .SingleImageMediaContainer_media__dwRvi {
      -o-object-position: right;
         object-position: right;
    }
.SingleImageMediaContainer_isBleed__utTbM.SingleImageMediaContainer_alignLeft__osgJZ .SingleImageMediaContainer_mediaWrapper__0Ofgz {
      left: 0;
    }
.SingleImageMediaContainer_isBleed__utTbM.SingleImageMediaContainer_alignLeft__osgJZ .SingleImageMediaContainer_media__dwRvi {
      -o-object-position: left;
         object-position: left;
    }

.SideBySides_sideBySides__HSWLZ {
  position: relative;
}

  .SideBySides_sideBySides__HSWLZ .SideBySides_sideBySideItem__MKfS0 + .SideBySides_sideBySideItem__MKfS0 {
    margin-top: var(--spacer-84);
  }

  @media (min-width: 768px) {

  .SideBySides_sideBySides__HSWLZ .SideBySides_sideBySideItem__MKfS0 + .SideBySides_sideBySideItem__MKfS0 {
      margin-top: var(--spacer-96)
  }
    }

  @media (min-width: 1240px) {

  .SideBySides_sideBySides__HSWLZ .SideBySides_sideBySideItem__MKfS0 + .SideBySides_sideBySideItem__MKfS0 {
      margin-top: var(--spacer-124)
  }
    }

.SideBySideStack_sideBySideStack__p4CgA {
  position: relative;
}

  .SideBySideStack_sideBySideStack__p4CgA .SideBySideStack_container__W6pMh {
    position: relative;
  }

  .SideBySideStack_sideBySideStack__p4CgA .SideBySideStack_wrapper__CsZ7u {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }

  @media (min-width: 768px) {

  .SideBySideStack_sideBySideStack__p4CgA .SideBySideStack_wrapper__CsZ7u {
      display: block
  }
    }

  .SideBySideStack_sideBySideStack__p4CgA .SideBySideStack_scrollingLockup__04Eir {
    flex: 1 0 auto;
  }

  @media (min-width: 768px) {

  .SideBySideStack_sideBySideStack__p4CgA .SideBySideStack_scrollingLockup__04Eir {
      align-items: center
  }
    }

  .SideBySideStack_sideBySideStack__p4CgA .SideBySideStack_paginationDots__amQJS {
    position: absolute;
    z-index: var(--z-index-5);
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  @media (min-width: 768px) {

  .SideBySideStack_sideBySideStack__p4CgA .SideBySideStack_paginationDots__amQJS {
      display: none
  }
    }

.StackedSideBySideWrapper_stackedSideBySideWrapper__pdcQ6 {
  --section-vertical-padding: calc(var(--spacer-96)/2);
  --card-stack-offset: -4rem;
  --active-progress: 0;
  position: relative;
  top: 0;
  order: -1;
  padding: 0;
}

  @media (min-width: 768px) {.StackedSideBySideWrapper_stackedSideBySideWrapper__pdcQ6 {
    position: sticky;
    top: var(--top-offset, 0);
    left: 0;
    order: 1;
    padding: var(--section-vertical-padding) 0;
    transform: translate3d(0, 0, 0);

    --section-vertical-padding: calc(var(--spacer-160)/2)
}

    .StackedSideBySideWrapper_stackedSideBySideWrapper__pdcQ6:first-child {
      margin-top: calc(var(--section-vertical-padding)*-1);
    }
  }

  @media (min-width: 1240px) {.StackedSideBySideWrapper_stackedSideBySideWrapper__pdcQ6 {
    --section-vertical-padding: calc(var(--spacer-240)/2)
}
  }

  .StackedSideBySideWrapper_stackedSideBySideWrapper__pdcQ6.StackedSideBySideWrapper_active__tObTa {
    --active-progress: 1;
  }

  @media (min-width: 768px) {

  .StackedSideBySideWrapper_stackedSideBySideWrapper__pdcQ6:last-of-type .StackedSideBySideWrapper_contentWrapper__I__z5 {
        transform: translate3d(0, calc((var(--card-enter-progress, 0))*-10rem), 0) scale(calc(1 - var(--card-next-progress, 0)*0.05))
    }
      }

  @media (min-width: 768px) {

  .StackedSideBySideWrapper_stackedSideBySideWrapper__pdcQ6 .StackedSideBySideWrapper_contentWrapper__I__z5 {
      /* Make the opacity happen 30% faster so that on tablet the fade-out happens slightly sooner */
      opacity: calc(1 - var(--card-leave-progress, 0)*1.3);
      transform: translate3d(0, calc((1 - var(--card-enter-progress, 0))*10rem + (var(--card-next-progress, 0))*-10rem), 0) scale(calc(1 - var(--card-next-progress, 0)*0.05));
      transition: transform 0.2s ease-out
  }
    }

  @media (min-width: 1240px) {

  .StackedSideBySideWrapper_stackedSideBySideWrapper__pdcQ6 .StackedSideBySideWrapper_contentWrapper__I__z5 {
      opacity: calc(1 - var(--card-leave-progress, 0))
  }
    }

  .StackedSideBySideWrapper_stackedSideBySideWrapper__pdcQ6 .StackedSideBySideWrapper_mediaContainer__81rSs {
    position: relative;
    margin-bottom: var(--spacer-64);
  }

  @media (min-width: 768px) {

  .StackedSideBySideWrapper_stackedSideBySideWrapper__pdcQ6 .StackedSideBySideWrapper_mediaContainer__81rSs {
      margin-bottom: 0;
      transform: translate3d(0, calc(var(--card-next-progress, 0)*var(--card-stack-offset)*var(--reverse-index)), 0)
  }
    }

  @media (min-width: 768px) {
      .StackedSideBySideWrapper_stackedSideBySideWrapper__pdcQ6 .StackedSideBySideWrapper_mediaOutro__vpOri::after {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        overflow: hidden;
        width: 100%;
        height: 100%;
        border-radius: var(--border-radius-5);
        background-color: black;
        content: '';
        opacity: calc(var(--card-next-progress, 0)*0.6);
        transform: scale(calc(1 - var(--card-next-progress, 1)*(0.05*var(--reverse-index))));
        transition: transform 0.2s ease-out;
      }
    }

  .StackedSideBySideWrapper_stackedSideBySideWrapper__pdcQ6 .StackedSideBySideWrapper_mediaWrapper__3qo7r {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    border: 1px solid var(--color-alpha-light-10);
    border-radius: var(--border-radius-10);
    background-color: black;
  }

  @media (min-width: 768px) {

  .StackedSideBySideWrapper_stackedSideBySideWrapper__pdcQ6 .StackedSideBySideWrapper_mediaWrapper__3qo7r {
      transform: scale(calc(1 - var(--card-next-progress, 1)*(0.05*var(--reverse-index))));
      transition: transform 0.2s ease-out
  }
    }

  .StackedSideBySideWrapper_stackedSideBySideWrapper__pdcQ6 .StackedSideBySideWrapper_media__K1hME {
    position: relative;
    transform: scale(calc(1 + (1 - var(--card-enter-progress, 0))*0.1));
    transition: transform 0.2s ease-out;
  }

.SpeedBump_speedbump__yi9Mc {
  position: relative;
  z-index: var(--z-index-2);

  --speedbump-radius: var(--border-radius-10);
}
.SpeedBump_noSpacerWrapper__grh16 {
  position: relative;
  width: 100%;
}
.SpeedBump_shadow___8oB8 {
  grid-column: wrapper-start/wrapper-end;
}
@media (min-width: 768px) {
.SpeedBump_shadow___8oB8 {
    grid-column: start/end
}
  }
.SpeedBump_contentContainer__P3hT2 {
  position: relative;
  padding: var(--spacer-48) 0;
}
@media (min-width: 768px) {
.SpeedBump_contentContainer__P3hT2 {
    display: block
}
  }
@media (min-width: 1240px) {
.SpeedBump_contentContainer__P3hT2 {
    padding: var(--spacer-64) 0
}
  }
.SpeedBump_content__ppNAQ {
  z-index: var(--z-index-2);
}
.SpeedBump_backgroundContainer__bUAB9 {
  position: absolute;
  width: 100%;
  height: 100%;
}
.SpeedBump_backgroundContainer__bUAB9::after {
    position: absolute;
    z-index: var(--z-index-1);
    display: block;
    border: solid 1px var(--color-alpha-light-10);
    border-radius: var(--speedbump-radius);
    content: '';
    inset: 0;
  }
@media (min-width: 768px) {
.SpeedBump_backgroundContainer__bUAB9 {
    grid-column: start/end
}
  }
.SpeedBump_backgroundOverlay__4S_pQ,
.SpeedBump_backgroundContainer__bUAB9 {
  overflow: hidden;
  border-radius: var(--speedbump-radius);
}
.SpeedBump_backgroundOverlay__4S_pQ {
  position: absolute;
  z-index: var(--z-index-1);
  width: 100%;
  height: 100%;
  inset: 0;
}
.SpeedBump_background__orxze {
  position: absolute;
  width: 100%;
  height: calc(100% + 4rem);
  inset: 0;
  transform-origin: top;
}
@media (min-width: 768px) {
.SpeedBump_background__orxze {
    height: calc(100% + 8rem)
}
  }

