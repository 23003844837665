.pricingCard {
  position: relative;
  display: flex;
  flex: 1 1 100%;
  flex-direction: column;
  padding: var(--spacer-24);
  border: 1px solid rgb(157 157 255 / 10%);
  border-radius: var(--border-radius-10);
  backdrop-filter: blur(var(--card-background-blur, 75px));
  background: #10101780;
  box-shadow:
    12px 12px 50px 0 #181826 inset,
    84.68px 149.711px 80px 0 rgb(0 0 0 / 31%),
    54.885px 97.035px 46.852px 0 rgb(0 0 0 / 26%),
    32.618px 57.666px 25.481px 0 rgb(0 0 0 / 23%),
    16.936px 29.942px 13px 0 rgb(0 0 0 / 20%),
    6.9px 12.199px 6.519px 0 rgb(0 0 0 / 18%),
    1.568px 2.772px 3.148px 0 rgb(0 0 0 / 20%);
  scroll-snap-align: start;

  @media (--md) {
    flex: 1;
  }

  @media (--lg) {
    flex: 1;
  }
}

/* 4 columns becomes 2x2 on medium breakpoint */
.pricingCardColumns4 {
  @media (--md) {
    flex: 1 1 calc(50% - var(--grid-gutter-size));
  }

  @media (--lg) {
    flex: 1;
  }
}

.highlightedCard {
  border: 1px solid rgb(157 157 255 / 50%);
  box-shadow:
    0 4px 74px 30px rgb(97 153 246 / 10%) inset,
    84.68px 149.711px 80px 0 rgb(0 0 0 / 31%),
    54.885px 97.035px 46.852px 0 rgb(0 0 0 / 26%),
    32.618px 57.666px 25.481px 0 rgb(0 0 0 / 23%),
    16.936px 29.942px 13px 0 rgb(0 0 0 / 20%),
    6.9px 12.199px 6.519px 0 rgb(0 0 0 / 18%),
    1.568px 2.772px 3.148px 0 rgb(0 0 0 / 20%);
}

.ctaRow {
  display: flex;
  flex-direction: row;

  @media (--md) {
    flex-direction: column;
  }
}

.pricingCardVariantReduced .ctaRow {
  padding-top: var(--spacer-40);
  margin-top: auto;
}

.cardTitle,
.cardEyebrow,
.cardContentWrapper {
  position: relative;
}

.cardIntro {
  margin-bottom: 0;

  @media (--md) {
    margin-bottom: var(--spacer-40);
  }

  &[data-variant='mini'] {
    margin-bottom: 0;

    @media (--md) {
      margin-bottom: var(--spacer-24);
    }
  }
}

.cardEyebrow {
  composes: eyebrow from '~typo';
  height: 1lh;
  margin-bottom: var(--spacer-8);
  color: var(--color-accent);

  @media (--md) {
    margin-bottom: var(--spacer-16);
  }
}

.cardTitle {
  composes: pricingOverviewCardPrice from '~typo';
  margin-right: var(--spacer-8);
  color: var(--grey-brand-grey-100, #fcfcfc);
}

.cardPriceWrapper {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  margin-bottom: var(--spacer-6);
}

.cardPriceContext {
  @media (--md) {
    height: 100%;
  }
}

.cardPriceContextLine {
  composes: label from '~typo';
  position: relative;
  display: block;
  min-height: 1lh;
  color: var(--alpha-brand-alpha-light-50, rgb(234 234 255 / 50%));
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 1.5;

  &:last-of-type {
    margin-top: -0.2rem;

    @media (--md) {
      margin-top: calc(-1 * var(--spacer-4));
    }
  }
}

.cardSecondaryTitle {
  composes: pricingOverviewCardPrice from '~typo';
  margin-right: var(--spacer-8);
  color: var(--alpha-brand-alpha-light-50, rgb(234 234 255 / 50%));
}

.cardPrice {
  composes: pricingOverviewCardPrice from '~typo';
  margin-right: var(--spacer-8);
  color: var(--alpha-brand-alpha-light-50, rgb(234 234 255 / 50%));
}

.cardDescription {
  composes: body from '~typo';
  padding-top: var(--spacer-24);
  padding-bottom: var(--spacer-24);
  border-top: 1px solid var(--color-alpha-hawkes-20);
  margin-top: var(--spacer-24);
  color: var(--alpha-brand-alpha-light-70, rgb(233 233 255 / 70%));
}

.pricingCardVariantReduced .cardDescription {
  border-bottom: 1px solid var(--color-alpha-hawkes-20);
}

.cardDescriptionContent {
  @media (--md) {
    min-height: 3lh;
  }
}

.cardTopBullets {
  position: relative;
  width: 100%;
}

.cardTopBullets,
.cardTopDescription {
  margin-bottom: var(--spacer-32);

  @media (--md) {
    margin-bottom: var(--spacer-40);
  }
}

.cardTopBulletItem {
  position: relative;
  width: 100%;
  margin-bottom: var(--spacer-6);

  &:last-child {
    margin-bottom: 0;
  }
}

.cardTopDescription,
.cardTopBulletText {
  composes: body from '~typo';
}

.cardDetails {
  transition: height var(--duration-300ms) var(--ease-basic-butter);
}

.cardDetailsTitle {
  composes: eyebrow from '~typo';
  padding-top: var(--spacer-32);
  color: var(--grey-brand-grey-100, #fcfcfc);

  @media (--md) {
    padding-top: 0;
  }
}

.cardDetailsTitle,
.cardDetailsSpacer {
  composes: body from '~typo';
  composes: medium from '~typo';
  margin-bottom: var(--spacer-8);
  color: var(--color-grey-97);

  @media (--md) {
    margin-bottom: var(--spacer-16);
  }
}

.cardDetailsSpacer {
  display: none;

  @media (--lg) {
    display: block;
  }
}

.cardDetailBullets {
  position: relative;
  width: 100%;
}

.cardDetailBulletItem {
  position: relative;
  width: 100%;
  padding-right: var(--spacer-24);
  margin-bottom: var(--spacer-8);

  @media (--md) {
    padding-right: unset;
  }
}

.cardDetailBulletCheck {
  position: relative;
  width: 1.2rem;
  height: auto;
  margin-right: var(--spacer-8);

  * {
    fill: var(--color-grey-97);
  }
}

.cardDetailBulletSvgButton,
.topDetailBulletSvgButton {
  position: absolute;
  top: calc(50% - 1rem);
  right: -0.5rem;
  left: auto;
  width: 2.4rem;
  height: 2.4rem;
  text-align: right;

  svg {
    path {
      fill: var(--alpha-brand-alpha-light-90, rgb(241 244 255 / 90%));
    }
  }
}

.cardDetailBulletSvg,
.topDetailBulletSvg {
  position: relative;
  width: 1.6rem;
  height: auto;
}

.cardDetailBulletTooltip,
.topDetailBulletTooltip {
  composes: body from '~typo';
  position: absolute;
  z-index: var(--z-index-2);
  right: 1rem;
  width: 100%;
  padding: var(--spacer-12) var(--spacer-24) var(--spacer-12) var(--spacer-20);
  border: 1px solid var(--color-alpha-light-10);
  border-radius: 1rem 1rem 0;
  background: var(--color-grey-05);
  opacity: 0;
  transform: translateY(calc(-100% - 3rem));
  transition:
    opacity 0.2s var(--ease-out),
    visibility 0.2s;
  visibility: hidden;

  $triangleSize: 12px;

  &::after {
    position: absolute;
    width: 4rem;
    height: $triangleSize;
    border-radius: 1rem 1rem 0;
    background: var(--color-grey-05);
    content: '';
    inset: auto 0 0 auto;
  }

  [data-is-active] & {
    opacity: 1;
    visibility: visible;
  }
}

.cardDetailBulletTooltipText,
.topDetailBulletTooltipText {
  display: flex;
  flex: 1;
}

.cardDetailBulletTooltipTail,
.topDetailBulletTooltipTail {
  position: absolute;
  top: 100%;
  right: -1px;
  width: $triangleSize;
  height: $triangleSize;
  border: 1px solid var(--color-alpha-light-10);
  background: var(--color-grey-05);
  transform: skewY(45deg) translateY(-50%);
}

.cardDetailBulletText,
.topDetailBulletText {
  composes: pricingCardBulletList from '~typo';
  color: var(--alpha-brand-alpha-light-70, rgb(233 233 255 / 70%));
}

.cardDetailButton {
  z-index: var(--z-index-2);
  width: 100%;

  @media (--md) {
    width: 100%;
  }
}

.viewAllFeaturesLink {
  composes: inlineLink from '~typo';
  padding-top: var(--spacer-40);
  font-family: var(--font-main, sans-serif);
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 600;
  text-decoration: none;

  @media (--md) {
    margin-top: auto;
  }

  svg {
    width: 1.2rem;
    height: 1.2rem;
    margin-left: var(--spacer-6);
  }

  @media (hover: hover) {
    &:hover {
      text-decoration: underline;
    }
  }
}
