.listItemsByIndex {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.listItemsByIndexItem {
  width: 100%;
  counter-increment: nav-counter;
  list-style: none;
}

.listItemsByIndexItemLink {
  composes: eyebrow from '~typo';
  display: flex;
  width: 100%;
  align-items: baseline;
  color: var(--default-color, var(--color-grey-100));
  text-transform: none;
  transition: color var(--duration-600ms) var(--ease-out);

  &:global(.active) {
    color: var(--active-color, var(--color-grey-100));
  }

  &::before {
    margin-right: var(--spacer-6);
    color: var(--color-alpha-light-50);
    content: counter(nav-counter, decimal-leading-zero);
    transition: color var(--duration-600ms) var(--ease-out);
  }

  .listItemsByIndexItemInner {
    color: inherit;
  }

  @media (--md) {
    width: unset;
  }
}

.listItemsByIndexItemInner {
  composes: subnavigation from '~typo';
  display: inline-block;
  width: 100%;
  padding-bottom: var(--row-gap, var(--spacer-12));
  text-transform: none;
  transition: transform var(--duration-600ms) var(--ease-basic-butter);

  @media (--md) {
    /* instead of using the gap property on the flexbox parent, we'll apply it as padding to the individual items so that there is a seamless transitioning when mousing over from one item to the next */
    padding-right: var(--grid-gutter-size);
    padding-bottom: var(--row-gap, var(--spacer-18));
  }
}
