.logoTileWall {
  --tile-border-radius: var(--border-radius-10);
  --tile-height: 8.4rem;
  --tile-width: auto;
  position: relative;
  display: flex;

  @media (--md) {
    --tile-height: 10.2rem;
    --tile-width: 31vw; /* 100vw / 3 */
  }

  @media (--lg) {
    --tile-width: 21vw; /* 100vw / 4.5 */
  }
}

.container {
  position: relative;
}
