.noPaddingWrapper {
  --sequence-top-offset: -600px;
  --sequence-height-factor: 0.8;
  --canvas-height: 100vw;
  --canvas-width: var(--canvas-height);
  position: relative;
  z-index: var(--z-index-neg);
  top: var(--sequence-top-offset);
  margin-bottom: calc(var(--sequence-top-offset) - (var(--sequence-2-height) * var(--sequence-height-factor) * 1px) - (50vh + (50vh - var(--canvas-height))) / 2 - (var(--canvas-height) * var(--sequence-offset, 0) / 200));
  @media (--md) {
    --sequence-top-offset: -900px;
    --canvas-height: 900px;
  }

  @media (min-height: 950px) {
    --sequence-height-factor: 1;
  }

  @media (min-height: 1200px) {
    --sequence-height-factor: 1.2;
  }

  @media (min-height: 1400px) {
    --sequence-height-factor: 1.4;
  }

  @media (min-height: 1600px) {
    --sequence-height-factor: 1.6;
  }

  .sequence {
    --sequence-local-height: var(--sequence-1-height);
    opacity: 0;

    .canvas {
      top: calc(100vh - var(--canvas-height));
      translate: 0 calc(-50% - (50vh - var(--canvas-height)));
    }
  }

  .sequence2 {
    position: relative;

    --sequence-local-height: var(--sequence-2-height);
    opacity: 0;

    .canvas {
      position: absolute;
      top: auto;
      bottom: calc(100% + (100vh - var(--canvas-height)) / 2);
      left: 50%;
      translate: -50% 0%;
    }
  }

  .sequence,
  .sequence2 {
    height: calc(var(--sequence-local-height) * 1px * var(--sequence-height-factor));
    text-align: center;

    .canvas {
      width: var(--canvas-width);
      height: var(--canvas-height);
      object-fit: contain;
    }
  }
}
