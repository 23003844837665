.pricingOverview {
  composes: grid from '~g';
  position: relative;
  z-index: var(--z-index-2);

  /* Adding spacer 48 which is equal to the banner height, always visible on pricing page */
  padding-top: calc(var(--announcement-banner-height) + var(--nav-height));
  padding-bottom: var(--spacer-48);
  opacity: 1;

  @media (--md) {
    padding-bottom: var(--spacer-96);
  }

  @media (--lg) {
    padding-top: calc(var(--announcement-banner-height) + var(--nav-height) + var(--spacer-124));
  }

  /* Hero Text Content */

  .content {
    margin-bottom: var(--spacer-24);

    @media (--md) {
      margin-bottom: var(--spacer-16);
    }

    @media (--lg) {
      margin-bottom: var(--spacer-32);
    }
  }

  /* Radio Buttons */

  .radioButtons {
    margin-bottom: var(--spacer-40);
    grid-column: start / end;
  }

  /* Card Wrappers */

  .pricingCardsWrapper,
  .customCardsWrapper {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    grid-column: wrapper-start / wrapper-end;

    @media (--md) {
      flex-flow: row nowrap;
      justify-content: flex-start;
      grid-column: start / end;
    }
  }

  .pricingCardsWrapper {
    padding: 0 var(--spacer-32);
    margin-bottom: var(--spacer-24);
    gap: var(--spacer-24) 0;

    @media (--md) {
      overflow: auto;
      flex-wrap: wrap;

      /* extra padding to prevent vertical scrolling from shadows */
      padding: 0 var(--grid-computed-margin-width) var(--spacer-480);
      margin-bottom: calc(-1 * var(--spacer-480) + var(--spacer-24));
      gap: var(--grid-gutter-size);
      grid-column: wrapper-start / wrapper-end;
      scroll-padding: var(--grid-computed-margin-width) 0 0 var(--grid-computed-margin-width);
      scroll-snap-type: x mandatory;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    @media (--lg) {
      overflow: unset;
      padding: 0;
      margin-bottom: var(--spacer-24);
      grid-column: start / end;
    }
  }
}
