$fullyDimmedOutPercentage: 0.5;
@value v_fullyDimmedOutPercentage: $fullyDimmedOutPercentage;

.antiPaddingWrapper {
  position: relative;
}

.endsWithModules {
  contain: paint;

  --module-padding-top: 0;

  &::after {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    height: 50vh;
    background: linear-gradient(to bottom, rgb(0 0 0 / 0%) 0%, rgb(0 0 0 / 100%) 100%);
    content: '';
  }
}

.canvas {
  height: 100vh;
}

.overlayWrapper {
  position: absolute;
  inset: 0 0 calc(-100vh * $fullyDimmedOutPercentage) 0;
}

.overlay {
  position: sticky;
  z-index: var(--z-index-1);
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  opacity: 1;
}

.modules {
  composes: grid from '~g';
  position: absolute;
  bottom: 0;
}

.sequenceWrapper {
  position: relative;

  --sequence-wrapper-height: calc((var(--sequence-height) - 100vh * $fullyDimmedOutPercentage));
  height: var(--sequence-wrapper-height);
}

.sequence {
  position: absolute;
  top: 0;
  left: 0;
}

.isRevealedModule {
  .sequenceWrapper {
    position: relative;

    /* if the module is revealed by a parallax subtract the parallax overlap amount (set on the parent section) from the sequence height so it doesn't end too early */
    height: calc(var(--sequence-wrapper-height) - var(--parallax-start-y, 0px));
  }
}
