.prompter {
  position: relative;
  z-index: var(--z-index-neg);
  margin-top: var(--sequence-top-offset);
  margin-bottom: 0;
  opacity: 0;

  /* ensures the prompter doesn't prevent clicks down below */
  pointer-events: none;

  /* Size of hero card transition */
  --sequence-top-offset: -150vh;
  --canvas-height: 100vw;
  --covered-module-negative-bottom-margin: 100vh;
}

.prompterSizer {
  position: absolute;
  inset: 0 0 100vh;
}

.contentWrapper {
  composes: grid from '~g';
  position: sticky;
  z-index: var(--z-index-1);
  top: 0;
  width: 100%;
  height: 100vh;
  align-items: center;
  margin-bottom: -100vh;
}

.contentOverlay {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100vh;
  background: linear-gradient(180deg, #000 18%, rgb(0 0 0 / 0%) 56.84%), linear-gradient(180deg, rgb(0 0 0 / 0%) 0%, #000 100%);
  opacity: 0.8;
  pointer-events: none;
}

.textWrapper {
  composes: subgrid from '~g';

  /* ensures the prompter doesn't prevent clicks down below */
  pointer-events: all;
}

.sequence {
  --section-overlap-top: calc(100vh + var(--corner-radius));
  --covered-module-negative-bottom-margin: -100vh;
}
