$MAX_PARALLAX: 8rem;
@value maxParallax: $MAX_PARALLAX;
$MAX_PARALLAX_SM: 4rem;
@value maxParallaxSm: $MAX_PARALLAX_SM;

$MAX_MEDIA_CONTAINER_HEIGHT_SM: 31.2rem;
$MAX_MEDIA_CONTAINER_HEIGHT_MD: 59.2rem;
$MAX_MEDIA_CONTAINER_HEIGHT_LG: 76.7rem;

.singleImageMediaContainer {
  position: relative;
  z-index: var(--z-index-2);
  width: 100%;
  align-self: center;
  margin-bottom: var(--spacer-40);
  grid-column: var(--single-media-column-start) / span var(--single-media-column-span);

  @media (--md) {
    margin-bottom: 0;
    grid-row: 1;
  }

  &.hasParallax {
    .media {
      height: calc(100% + $MAX_PARALLAX_SM);
      transform-origin: top;
      transition: transform var(--duration-150ms) var(--ease-out);

      @media (--md) {
        height: calc(100% + $MAX_PARALLAX);
      }
    }
  }

  .shadow {
    width: 100%;
    height: 100%;
  }

  .mediaWrapper {
    --side-by-side-progress: 0.5;
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    border-radius: var(--side-by-side-radius, var(--border-radius-10));
    pointer-events: initial;

    /* Safari fix for the border-radius */
    transform: translate3d(0, 0, 0);

    .hasLogo &::after {
      position: absolute;
      display: block;
      border-radius: var(--side-by-side-radius, var(--border-radius-5));
      background-color: var(--color-alpha-black-20);
      content: '';
      inset: 0;
    }
  }

  .mediaWrapperContent {
    height: 100%;
  }

  .media {
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.logo {
  position: absolute;
  z-index: var(--z-index-3);
  top: auto;
  bottom: var(--spacer-32);
  left: var(--spacer-32);
  width: auto;

  @media (--lg) {
    bottom: var(--spacer-40);
    left: var(--spacer-40);
  }

  * {
    fill: var(--color-grey-100);
  }
}

.isBleed {
  height: var(--max-bleed-media-height);
  text-align: right;

  --max-bleed-media-height: $MAX_MEDIA_CONTAINER_HEIGHT_SM;
  @media (--md) {
    --max-bleed-media-height: $MAX_MEDIA_CONTAINER_HEIGHT_MD;
  }
  @media (--lg) {
    --max-bleed-media-height: $MAX_MEDIA_CONTAINER_HEIGHT_LG;
  }

  .mediaWrapper {
    position: absolute;
    top: 50%;
    width: 200%;
    max-width: calc(var(--max-bleed-media-height) * var(--media-aspect-ratio));
    transform: translateY(-50%);

    @media (--md) {
      width: 300%;
    }

    @media (--lg) {
      width: 200%;
    }
  }

  &.alignRight {
    .mediaWrapper {
      right: 0;
    }

    .media {
      object-position: right;
    }
  }

  &.alignLeft {
    .mediaWrapper {
      left: 0;
    }

    .media {
      object-position: left;
    }
  }
}
