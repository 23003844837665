.potholeV4 {
  composes: grid from '~g';
  position: relative;

  /* By default, overflowing potholes should always sits at z-index 0 if never overriden */
  z-index: var(--module-z-index, var(--z-index-0));

  /* calculate height based on base aspect ratio plus any additional padding needed to accomodate for overlap and parallax */
  align-items: end;
  padding-top: var(--module-padding-top);
  padding-bottom: var(--module-padding-bottom);

  --module-padding-bottom: var(--spacer-64);
  --module-padding-top: var(--spacer-64);

  &.isLastModule {
    /* hack to avoid the clip created by svg mask */
    --module-padding-bottom: var(--spacer-320);
    --module-padding-top: 0;
  }

  &.isFirstModule {
    /* extra padding for the VC to peek through */
    --module-padding-top: 29.6rem;
    --module-padding-bottom: 0;
  }

  @media (--md) {
    --pothole-aspect-ratio: 1.6;
    min-height: 70rem;

    &.isFirstModule {
      --module-padding-top: 0;
    }

    &.isLastModule {
      min-height: calc(70rem + var(--module-padding-bottom));

      /* apply the parallax as negative margin */
      --covered-module-negative-bottom-margin: var(--parallax-start-y);
      --module-padding-bottom: calc(var(--covered-module-negative-bottom-margin) * 0.5);
    }
  }

  @media (--lg) {
    --pothole-aspect-ratio: 1.8;
  }
}

.container {
  composes: subgrid from '~g';
  grid-column: start / end;
}

.backgroundGrid {
  composes: grid from '~g';
  grid-column: start / end;

  .mediaAlignBottom & {
    grid-row: 2;
    @media (--md) {
      grid-row: unset;
    }
  }

  @media (--md) {
    position: absolute;
    z-index: var(--z-index-0);
    grid-column: unset;
    inset: 0;
  }
}

.backgroundContainer {
  position: relative;
  height: 100%;

  &.overflow {
    .backgroundOverlay {
      bottom: calc(var(--background-offset) * -1);
      overflow: visible;
      height: var(--pothole-media-size);
      max-height: 150rem;
      aspect-ratio: 1;

      --pothole-media-size: 150vw;
      @media (--md) {
        overflow: visible;
        max-height: 150rem;

        --pothole-media-size: calc(var(--grid-computed-margin-width) * 2 + var(--grid-computed-inner-width));
      }
    }

    .isFirstModule & .backgroundOverlay,
    .mediaAlignTop & .backgroundOverlay {
      bottom: calc(var(--background-offset) * -1);
      @media (--md) {
        top: auto;
        bottom: calc(var(--background-offset) * -1);
      }
    }

    .isLastModule & .backgroundOverlay,
    .mediaAlignBottom & .backgroundOverlay {
      top: calc(var(--background-offset) * -1);

      @media (--md) {
        top: calc(var(--background-offset) * -1);
        bottom: auto;
      }
    }
  }

  &.fullWidth {
    position: absolute;
    inset: 0;

    .backgroundOverlay {
      width: 100%;
    }
  }

  &.rightAligned {
    grid-column: start / end;

    .backgroundOverlay {
      /* need to be center in the middle of the screen on small */
      right: 50%;
      left: auto;
      aspect-ratio: 1;
      transform: translate(var(--pothole-media-translate-x), 0);

      --pothole-media-translate-x: 50%;

      @media (--md) {
        /* right aligned */
        right: 0;

        --pothole-media-translate-x: calc(50% - var(--grid-computed-column-width));
      }
    }
  }
}

.backgroundOverlay {
  /* Handles the offset of the pothole media asset from the top/bottom of the container */
  --background-offset: 5rem;
  position: absolute;
  left: 0;
  overflow: hidden;
  height: 100%;

  .isFirstModule & {
    --background-offset: 5rem;
    @media (--md) {
      --background-offset: 1rem;
    }
    @media (--lg) {
      --background-offset: 20rem;
    }
  }

  .isLastModule & {
    --background-offset: 5rem;
    @media (--md) {
      --background-offset: 1rem;
    }
    @media (--lg) {
      --background-offset: 0;
    }
  }

  .backgroundMedia {
    transform: scale(var(--media-scale, 1));
  }

  &::after {
    position: absolute;

    /* custom gradient overlay */
    background: linear-gradient(0deg, rgb(0 0 0 / 20%) 0%, rgb(0 0 0 / 20%) 100%), linear-gradient(19deg, rgb(0 0 0 / 67%) 21.85%, rgb(0 0 0 / 0%) 73.55%);
    content: '';
    inset: 0;
  }
}

.overlay {
  z-index: var(--z-index-1);
}

.content {
  align-self: center;
  padding: 5.6rem 0;

  .topAligned & {
    align-self: start;
  }

  .bottomAligned & {
    align-self: end;
  }
}

.potholeGlow {
  position: absolute;
  z-index: var(--z-index-3);
  background: var(--glow-background, transparent);
  inset: -20rem;
  mix-blend-mode: screen;
  pointer-events: none;
  @media (--md) {
    inset: -30rem;
  }
}
