$ASPECT_RATIO: 1260/839;
$ASPECT_RATIO_MOBILE: 1260/862;
$SEQUENCE_OFFSET: 20vh;
$SEQUENCE_OFFSET_MOBILE: 10vh;

.antiPaddingWrapper {
  position: relative;
  grid-column: wrapper-start / wrapper-end;
}

.laptopCloseImageSequence {
  composes: grid from '~g';
  position: relative;

  @media (prefers-reduced-motion) {
    .sequence {
      height: 0;
      visibility: hidden;
    }

    .modules:not(.postSequence) {
      position: unset;
      padding-bottom: 0;
      aspect-ratio: unset;
      grid-column: 1 / span 12;
    }
  }
}

.modules {
  &:not(.postSequence) {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    aspect-ratio: $ASPECT_RATIO_MOBILE;
    @media (--md) {
      justify-content: flex-end;
      padding-bottom: var(--spacer-96);
      aspect-ratio: $ASPECT_RATIO;
    }
  }

  &.postSequence {
    grid-column: wrapper-start / wrapper-end;

    &::before {
      display: block;
      aspect-ratio: 1166/56;
      content: '';
      grid-column: start/end;
    }
  }
}

.desktop {
  composes: grid from '~g';
  display: none;
  @media (--md) {
    display: grid;
  }

  &::after {
    display: block;
    aspect-ratio: 1166/56;
    content: '';
    grid-column: start/end;
  }
}

.modulesWrapper {
  composes: subgrid from '~g';
  position: relative;
}

.modulesWrapperMobile {
  composes: grid from '~g';
  position: relative;
  grid-column: wrapper-start / wrapper-end;
}

.mobile {
  composes: grid from '~g';
  @media (--md) {
    display: none;
  }
}

.sequenceWrapper {
  composes: subgrid from '~g';
  position: relative;
  z-index: var(--z-index-3);
  height: auto;
  grid-column: start / end;
  pointer-events: none;
}

.sequence {
  position: relative;

  /* By offsetting the sequence we make the intersection observer trigger later than expected */

  /* We then compensate by translating the canvas back by the same amount */
  top: $SEQUENCE_OFFSET_MOBILE;

  @media (--md) {
    top: $SEQUENCE_OFFSET;
  }
  height: auto;
  aspect-ratio: $ASPECT_RATIO;
  grid-column: start / end;
}

.canvas {
  position: absolute;
  top: calc(-1 * $SEQUENCE_OFFSET_MOBILE);
  width: 178.559%;
  translate: -21.95%;
  @media (--md) {
    top: calc(-1 * $SEQUENCE_OFFSET);
    width: 172.5%;
    translate: -20.95%;
  }
}
