.manifesto {
  --rounded-top-padding: 0;
  --scroll-progress-opacity: 0;
  --scroll-progress-y: 0;
}

.container {
  composes: grid from '~g';
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  align-content: flex-end;

  @media (--md) {
    height: initial;

    /* aspect ratio of 1.77 since width will always be 100vw */
    min-height: 56.5vw;
    align-content: center;
  }

  .body,
  .button {
    color: var(--color-grey-100);
  }

  .text {
    margin-bottom: var(--spacer-24);
    color: var(--color-grey-100);

    @media (--md) {
      margin-bottom: var(--spacer-32);
      text-align: center;
    }

    @media (--lg) {
      margin-bottom: var(--spacer-48);
    }
  }
}

.mediaWrapper {
  position: absolute;
  overflow: hidden;
  max-height: calc(100vh - var(--spacer-48) * 2);
  border-radius: var(--border-radius-10);
  inset: var(--spacer-48);
  transform: scale(var(--manifesto-video-scale, 1));
  transition: transform var(--duration-600ms) var(--ease-basic-butter);

  &.isActive {
    transform: scale(1);
  }
}

.videoWrapper {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  inset: 0;
  opacity: 0;
  transition: opacity var(--duration-600ms) var(--ease-basic-butter);

  &.isActive {
    opacity: 1;
  }
}

.backgroundWrapper,
.backgroundImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.backgroundWrapper {
  background: var(--color-grey-00);
  opacity: 1;
  transform: scale(1.1);
  transition:
    opacity var(--duration-600ms) var(--ease-basic-butter),
    transform var(--duration-600ms) var(--ease-basic-butter);

  &.isHidden {
    opacity: 0;
    pointer-events: none;
    transform: scale(1);
    transition:
      opacity var(--duration-600ms) var(--ease-basic-butter) var(--duration-400ms),
      transform var(--duration-600ms) var(--ease-basic-butter);
  }
}

.closeButton {
  position: absolute;
  z-index: var(--z-index-2);
  top: var(--spacer-12);
  right: var(--spacer-12);
  width: var(--spacer-40);
  height: var(--spacer-40);
  box-shadow: var(--button-box-shadow);

  @media (--md) {
    top: var(--spacer-40);
    right: var(--spacer-40);
  }
}

.backgroundImage {
  height: calc(100% + 12vh);
  opacity: var(--scroll-progress-opacity);
  transform: translate3d(0, var(--scroll-progress-y), 0);
}

.contentWrapper {
  --content-hidden-progress: 0;
  composes: subgrid from '~g';
  position: relative;
  height: 100%;
  padding-bottom: var(--spacer-32);
  grid-column: start / end;

  &.isHidden {
    --content-hidden-progress: 1;
    pointer-events: none;
  }

  @media (--md) {
    padding: var(--spacer-124) 0;
  }
}

.text {
  opacity: calc(1 - var(--content-hidden-progress));
  transform: translateY(calc(var(--content-hidden-progress) * 1rem));
  transition:
    opacity var(--duration-600ms) var(--ease-basic-butter),
    transform var(--duration-600ms) var(--ease-basic-butter);
  transition-delay: var(--duration-100ms);
}

.body {
  composes: bodyExtraLarge from '~typo';
}

.buttonWrapper {
  position: relative;
  display: flex;
  grid-column: start / end;
  grid-row: 2;
  opacity: calc(1 - var(--content-hidden-progress));
  place-content: flex-start;
  transform: translateY(calc(var(--content-hidden-progress) * 2rem));
  transition:
    opacity var(--duration-600ms) var(--ease-basic-butter),
    transform var(--duration-600ms) var(--ease-basic-butter);
  transition-delay: var(--duration-150ms);

  @media (--md) {
    place-content: center;
  }
}

.button {
  position: relative;
  display: flex;
  align-items: center;

  &:hover {
    --item-hover-progress: 1;

    path {
      fill: var(--button-hover-color);
    }
  }
}

.buttonLabel {
  composes: button from '~typo';
  align-self: center;
  padding-left: var(--spacer-16);
  white-space: nowrap;
}
