.stackedSideBySideWrapper {
  --section-vertical-padding: calc(var(--spacer-96) / 2);
  --card-stack-offset: -4rem;
  --active-progress: 0;
  position: relative;
  top: 0;
  order: -1;
  padding: 0;

  @media (--md) {
    position: sticky;
    top: var(--top-offset, 0);
    left: 0;
    order: 1;
    padding: var(--section-vertical-padding) 0;
    transform: translate3d(0, 0, 0);

    --section-vertical-padding: calc(var(--spacer-160) / 2);

    &:first-child {
      margin-top: calc(var(--section-vertical-padding) * -1);
    }
  }

  @media (--lg) {
    --section-vertical-padding: calc(var(--spacer-240) / 2);
  }

  &.active {
    --active-progress: 1;
  }

  &:last-of-type {
    .contentWrapper {
      @media (--md) {
        transform: translate3d(0, calc((var(--card-enter-progress, 0)) * -10rem), 0) scale(calc(1 - var(--card-next-progress, 0) * 0.05));
      }
    }
  }

  .contentWrapper {
    @media (--md) {
      /* Make the opacity happen 30% faster so that on tablet the fade-out happens slightly sooner */
      opacity: calc(1 - var(--card-leave-progress, 0) * 1.3);
      transform: translate3d(0, calc((1 - var(--card-enter-progress, 0)) * 10rem + (var(--card-next-progress, 0)) * -10rem), 0) scale(calc(1 - var(--card-next-progress, 0) * 0.05));
      transition: transform 0.2s ease-out;
    }
    @media (--lg) {
      opacity: calc(1 - var(--card-leave-progress, 0));
    }
  }

  .mediaContainer {
    position: relative;
    margin-bottom: var(--spacer-64);
    @media (--md) {
      margin-bottom: 0;
      transform: translate3d(0, calc(var(--card-next-progress, 0) * var(--card-stack-offset) * var(--reverse-index)), 0);
    }
  }

  .mediaOutro {
    @media (--md) {
      &::after {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        overflow: hidden;
        width: 100%;
        height: 100%;
        border-radius: var(--border-radius-5);
        background-color: black;
        content: '';
        opacity: calc(var(--card-next-progress, 0) * 0.6);
        transform: scale(calc(1 - var(--card-next-progress, 1) * (0.05 * var(--reverse-index))));
        transition: transform 0.2s ease-out;
      }
    }
  }

  .mediaWrapper {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    border: 1px solid var(--color-alpha-light-10);
    border-radius: var(--border-radius-10);
    background-color: black;

    @media (--md) {
      transform: scale(calc(1 - var(--card-next-progress, 1) * (0.05 * var(--reverse-index))));
      transition: transform 0.2s ease-out;
    }
  }

  .media {
    position: relative;
    transform: scale(calc(1 + ((1 - var(--card-enter-progress, 0)) * 0.1)));
    transition: transform 0.2s ease-out;
  }
}
