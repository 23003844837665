$MAX_PARALLAX: 8rem;
@value maxParallax: $MAX_PARALLAX;
$MAX_PARALLAX_SM: 4rem;
@value maxParallaxSm: $MAX_PARALLAX_SM;

.speedbump {
  position: relative;
  z-index: var(--z-index-2);

  --speedbump-radius: var(--border-radius-10);
}

.noSpacerWrapper {
  composes: grid from '~g';
  position: relative;
  width: 100%;
}

.shadow {
  grid-column: wrapper-start/wrapper-end;

  @media (--md) {
    grid-column: start/end;
  }
}

.contentContainer {
  composes: grid from '~g';
  position: relative;
  padding: var(--spacer-48) 0;
  @media (--md) {
    display: block;
  }

  @media (--lg) {
    padding: var(--spacer-64) 0;
  }
}

.content {
  z-index: var(--z-index-2);
}

.backgroundContainer {
  position: absolute;
  width: 100%;
  height: 100%;

  &::after {
    position: absolute;
    z-index: var(--z-index-1);
    display: block;
    border: solid 1px var(--color-alpha-light-10);
    border-radius: var(--speedbump-radius);
    content: '';
    inset: 0;
  }

  @media (--md) {
    grid-column: start/end;
  }
}

.backgroundOverlay,
.backgroundContainer {
  overflow: hidden;
  border-radius: var(--speedbump-radius);
}

.backgroundOverlay {
  position: absolute;
  z-index: var(--z-index-1);
  width: 100%;
  height: 100%;
  inset: 0;
}

.background {
  position: absolute;
  width: 100%;
  height: calc(100% + $MAX_PARALLAX_SM);
  inset: 0;
  transform-origin: top;
  @media (--md) {
    height: calc(100% + $MAX_PARALLAX);
  }
}
