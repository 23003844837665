.row {
  display: flex;
  width: 100%;
  flex-flow: row nowrap;
  align-items: center;
  margin-bottom: var(--spacer-10);
  margin-left: calc(100vw - 43.5%);
  gap: var(--spacer-10);

  @media (--md) {
    margin-bottom: var(--spacer-12);
    margin-left: calc(100vw - 54.2%);
    gap: var(--spacer-12);
  }

  @media (--lg) {
    margin-left: -2rem;
  }

  &:nth-child(2n) {
    margin-left: calc(100vw - 33.7%);

    @media (--md) {
      margin-left: calc(100vw - 46%);
    }

    @media (--lg) {
      margin-left: -8vw;
    }
  }

  &:last-of-type {
    margin-bottom: initial;
  }
}

.duplicate {
  display: flex;
  flex-flow: row nowrap;
  gap: var(--spacer-10);

  @media (--md) {
    gap: var(--spacer-12);
  }
}

.logoTile {
  position: relative;
  display: flex;
  width: var(--tile-width);
  min-width: 21.2rem;
  max-width: 30.2rem;
  height: var(--tile-height);
  flex-flow: row nowrap;
  padding: 0 var(--spacer-28);
  border: 0.05rem solid var(--color-alpha-perano-10);
  border-radius: var(--tile-border-radius);
  background-color: var(--color-grey-05);

  @media (--lg) {
    max-width: none;
  }
}

.logoWrapper {
  position: relative;
  display: flex;
  width: 2.6rem;
  height: 100%;
  align-items: center;
  justify-content: center;
  margin-right: var(--spacer-24);

  @media (--md) {
    --graphic-scale: 32 / 26;
    width: 3.2rem;
  }
}

.logo {
  display: flex;
}

.label {
  composes: ellipsis from '~deco';
  composes: body from '~typo';
  display: inline-block;
  align-self: center;
  color: var(--color-grey-80);
  line-height: var(--tile-height);
}
