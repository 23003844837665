.liveScheduleContainer {
  composes: grid from '~g';
}

.liveScheduleContent {
  grid-column: start / end;

  @media (--md) {
    grid-column: start / span 12;
  }
}

.liveScheduleNavigation {
  --bg: rgb(27 27 38 / 100%);
  position: relative;
  overflow: hidden;
  border-radius: var(--border-radius-6);
  background-color: var(--bg);

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, var(--bg) 0%, rgb(0 0 0 / 0%) 10%, rgb(0 0 0 / 0%) 90%, var(--bg) 100%);
    content: '';
    opacity: 0;
    pointer-events: none;
    transition: opacity var(--duration-200ms) var(--ease-basic-butter);

    @media (--md) {
      background: linear-gradient(90deg, var(--bg) 0%, rgb(0 0 0 / 0%) 6%, rgb(0 0 0 / 0%) 94%, var(--bg) 100%);
    }
  }
}

.liveScheduleNavigationOverflow {
  overflow-x: scroll;

  &::after {
    opacity: 1;
  }
}

.liveScheduleNavigation ol {
  display: flex;
  overflow: hidden;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: left;
  margin: 0;

  li {
    min-width: var(--spacer-240);
    text-align: center;
  }
}

.liveScheduleNavigationOverflow ol {
  justify-content: space-between;
}

.liveScheduleEventButton button {
  padding: var(--spacer-24);
  color: var(--color-grey-60);
  transition: color var(--duration-200ms) var(--ease-basic-butter);
  white-space: nowrap;

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

.liveScheduleSelectedEventButton button {
  color: var(--color-grey-88);
}

.liveScheduleEventDetails {
  margin-top: var(--spacer-32);
}

.eventTitlePortableText {
  p {
    grid-column: start / span 12;
  }
}

.speakersList {
  display: flex;
  flex-flow: column wrap;
  align-items: center;

  li {
    width: 100%;
  }

  @media (--md) {
    width: auto;
    flex-direction: row;
  }

  .speakerItem {
    min-width: 50%;
    margin-top: var(--spacer-32);

    @media (--md) {
      width: 50%;
    }
  }
}

.speaker {
  display: flex;
  align-items: center;

  p {
    margin-top: 0;
  }

  .speakerAvatar {
    margin-right: var(--spacer-32);

    img {
      width: var(--spacer-64);
      height: var(--spacer-64);
      border-radius: 50%;
    }
  }
}

.eventCta {
  margin: var(--spacer-32) 0;
}

.nonGridPortableText {
  display: block;

  p {
    width: 100%;
  }
}
