$MULTI_MEDIA_FOREGROUND_MAX_PARALLAX: 12%;
$MAX_MEDIA_CONTAINER_HEIGHT: 80rem;

.multiImageMediaContainer {
  composes: grid from '~g';
  position: relative;
  z-index: var(--z-index-2);
  max-height: $MAX_MEDIA_CONTAINER_HEIGHT;
  align-items: flex-start;
  aspect-ratio: 0.75;
  grid-column: wrapper-start / wrapper-end;

  @media (--md) {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    aspect-ratio: 0.7;
    grid-column: var(--multi-media-column-start) / span var(--multi-media-column-span);
    grid-row: 1;
    place-content: center;
  }

  @media (--lg) {
    aspect-ratio: 0.93;
  }

  .mediaForegroundElementWrapper {
    position: relative;

    .mediaForegroundElement {
      height: auto;
    }
  }

  .mediaBackgroundContainer {
    width: 100%;
    height: 100%;
    grid-column: 2 / end;

    .shadow {
      min-width: 100%;
      height: 100%;
    }

    .innerShadow {
      bottom: 21px;
    }

    .glassWrapper {
      min-width: 100%;
      height: 100%;
      aspect-ratio: var(--default-image-aspect-ratio);
    }

    .mediaBackgroundElement {
      position: relative;
      min-width: 100%;
      height: 100%;
      object-fit: cover;

      video {
        object-fit: cover;
      }
    }
  }

  .mediaForegroundContainer {
    position: absolute;
    z-index: 2;
    display: block;
    grid-column: start / end;
  }

  .glassWrapperContent {
    width: 100%;
    height: 100%;
  }

  &.alignLeft {
    .mediaBackgroundElement {
      object-position: left;
    }

    .innerShadow {
      left: var(--background-offset);
    }

    .mediaBackgroundContainer .glassWrapper {
      left: var(--background-offset);

      video {
        object-position: left;
      }
    }

    .mediaForegroundContainer {
      @media (--md) {
        left: 0;
      }
    }
  }

  &.alignRight {
    .mediaBackgroundElement {
      object-position: right;
    }

    .innerShadow {
      right: var(--background-offset);
    }

    .mediaBackgroundContainer .glassWrapper {
      right: var(--background-offset);

      video {
        object-position: right;
      }
    }

    .mediaForegroundContainer {
      justify-self: end;

      @media (--md) {
        right: 0;
      }
    }
  }
}

.wideAspectRatioContainer {
  --background-offset: 26%;

  @media (--md) {
    --background-offset: 14%;
  }

  @media (--lg) {
    --background-offset: 9rem;
  }

  @media (--xl) {
    --background-offset: 9.5rem;
  }

  .mediaBackgroundContainer {
    .glassWrapper {
      position: absolute;
      height: 100%;
    }
  }

  .mediaForegroundContainer {
    top: 46%;
    width: 140%;
    max-width: 58rem;

    @media (--md) {
      top: 46%;
      width: 82%;
      margin-top: 0;
    }

    @media (--lg) {
      width: 70%;
    }
  }
}

.narrowAspectRatioContainer {
  --background-offset: 26%;

  @media (--md) {
    --background-offset: 14%;
    height: 100%;
  }

  @media (--lg) {
    --background-offset: 9rem;
  }

  @media (--xl) {
    --background-offset: 9.5rem;
  }

  .mediaBackgroundContainer {
    margin-top: 8%;

    .glassWrapper {
      position: absolute;
      height: 95%;
    }

    @media (--md) {
      margin-top: 0;
    }
  }

  .mediaForegroundContainer {
    width: 79%;

    @media (--md) {
      display: flex;
      width: 65%;
      max-width: 36rem;
      height: 100%;
      flex-direction: column;
      justify-content: center;
    }

    @media (--lg) {
      width: 50%;
    }
  }
}

/* Parallax */
.mediaForegroundContainer {
  --max-parallax-y: $MULTI_MEDIA_FOREGROUND_MAX_PARALLAX;
}

.mediaBackgroundContainer,
.mediaForegroundContainer {
  @media (--md) {
    --translate-y: calc((var(--multi-media-container-progress) * (-1 * var(--max-parallax-y) - var(--max-parallax-y))) / 1 + var(--max-parallax-y));
    transform: translateY(var(--translate-y));
    transition: transform 0.5s var(--ease-mini-bounce);
  }
}
