.mosaic {
  --module-padding-bottom: 0;

  /* this value gets multiplied by -1 in the module wrapper, so we're actually making it a positive value here */
  --covered-module-negative-bottom-margin: -1 * var(--spacer-84);
  @media (--md) {
    --covered-module-negative-bottom-margin: calc(var(--margin-with-parallax) / 3);
  }
}

.container {
  composes: grid from '~g';
  position: relative;
}

.mosaicContainer {
  position: relative;
  display: flex;
  width: calc(var(--eight-cols) + (var(--content-width) + (var(--grid-gutter-size))));
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-top: calc(-1 * var(--margin-with-parallax));
  margin-left: calc(-1 * var(--four-cols) - var(--grid-gutter-size) / 2);
  column-gap: var(--spacer-24);
  grid-column: start / end;
  pointer-events: none;

  --content-width: 100%;
  --eight-cols: (var(--content-width) / var(--grid-number-of-columns)) * 8;
  --four-cols: (var(--content-width) / var(--grid-number-of-columns)) * 4;

  @media (--md) {
    display: grid;
    margin-top: calc(-1 * var(--margin-with-parallax) / 2.5);
    column-gap: var(--grid-gutter-size);
    grid-template-columns: repeat(20, 1fr);
  }
}

.itemsDesktop {
  display: none;
  @media (--md) {
    display: grid;
  }
}

.itemsMobile {
  display: grid;
  @media (--md) {
    display: none;
  }
}

.column {
  position: relative;
  display: flex;
  width: 23%;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  @media (--md) {
    top: var(--offset-y, 0);
    width: unset;
    grid-column: span 4;
    row-gap: var(--grid-gutter-size);

    &:nth-child(1) {
      --offset-y: -1rem;
    }

    &:nth-child(2) {
      --offset-y: 3rem;
    }

    &:nth-child(4) {
      --offset-y: 1.3rem;
    }
  }
  row-gap: var(--spacer-24);
}

.item {
  position: relative;
  overflow: hidden;
  width: 100%;
  flex-shrink: 0;
  background-color: var(--color-alpha-black-100);
  gap: 20px;
  mask-image: var(--top-bottom-mask-10px);
}
