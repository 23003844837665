$MAX_PARALLAX: 0.28;

.pothole {
  composes: grid from '~g';
  position: relative;
  overflow: hidden;

  --title-4-pothole-column-start: col 1;
  --covered-module-negative-bottom-margin: 0;

  /* calculate height based on base aspect ratio plus any additional padding needed to accomodate for overlap and parallax */
  $height: calc(((85vw / var(--pothole-aspect-ratio, 0.56)) + calc(var(--section-overlap-top, 0px) + var(--section-overlap-bottom, 0px))));
  min-height: $height;
  align-items: end;

  @media (--md) {
    --pothole-aspect-ratio: 1.6;
  }

  @media (--lg) {
    --pothole-aspect-ratio: 1.8;
  }

  --module-padding-top: 0;
  --module-padding-bottom: 0;
}

.container {
  composes: subgrid from '~g';
  grid-column: start / end;
}

.backgroundContainer {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% + 100% * ($MAX_PARALLAX / 2));
  object-fit: cover;

  /* translate the image from -y value to 0 */
  transform: translateY(calc(var(--pothole-progress, 0) * ($height * $MAX_PARALLAX) / 1 - ($height * $MAX_PARALLAX)));
  transform-origin: top;
}

.backgroundOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.overlay {
  z-index: var(--z-index-1);
}

.contentContainer {
  z-index: var(--z-index-4);
  padding-top: var(--spacer-480);

  /* padding bottom here is to counteract the garage door */
  padding-bottom: var(--spacer-32);
  margin-bottom: var(--spacer-124);
  grid-column: start / end;

  --body-xl-block-column-start: start;
  --body-xl-block-graphic-margin-bottom: var(--spacer-12);
}
