.sideBySide {
  composes: grid from '~g';
  display: grid;

  --side-by-side-radius: var(--border-radius-10);
  --shadow-radius: var(--side-by-side-radius);

  /* Single image media config */
  --single-media-column-start: start;
  --single-media-column-span: var(--grid-number-of-columns);

  .titleContent {
    @media (--lg) {
      --lockup-column-span: 5;
    }
  }

  &.isStackCard {
    .titleContent {
      --lockup-column-span: unset;
    }

    .mediaContainer {
      order: -1;

      @media (--md) {
        order: inherit;
      }
    }
  }

  &.isTextRight {
    @media (--md) {
      /* Single image media config */
      --single-media-column-start: col 1;
      --single-media-column-span: calc(var(--grid-number-of-columns) / 2);

      /* Multi image media config */
      --multi-media-column-start: wrapper-start;
      --multi-media-column-span: calc(var(--grid-number-of-columns) / 2 + 1);

      /* Text lockup grid config */
      --lockup-grid-start: calc(var(--grid-number-of-columns) / 2 + 2);

      &.isStackCard {
        --single-media-column-start: col 1;
        --single-media-column-span: calc(var(--grid-number-of-columns) / 2 + 1);
        --lockup-grid-start: calc(var(--grid-number-of-columns) / 2 + 3);
      }

      &.isBleed {
        --single-media-column-start: wrapper-start;
        --single-media-column-span: calc(var(--grid-number-of-columns) / 2 + 1);
        --lockup-grid-start: calc(var(--grid-number-of-columns) / 2 + 2);
      }
    }

    .mediaContainer {
      @media (--md) {
        order: -1;
        justify-self: end;
      }
    }
  }

  &.isTextLeft {
    @media (--md) {
      /* Single image media config */
      --single-media-column-start: calc(var(--grid-number-of-columns) / 2 + 2);
      --single-media-column-span: calc(var(--grid-number-of-columns) / 2);

      /* Multi image media config */
      --multi-media-column-start: calc(var(--grid-number-of-columns) / 2 + 2);
      --multi-media-column-span: calc(var(--grid-number-of-columns) / 2 + 1);

      /* Text lockup grid config */
      --lockup-grid-start: col 1;

      &.isMultiImage {
        --lockup-grid-start: col 1;
      }

      &.isStackCard {
        --single-media-column-start: calc(var(--grid-number-of-columns) / 2 + 1);
        --single-media-column-span: calc(var(--grid-number-of-columns) / 2);
        --lockup-grid-start: col 1;

        .mediaContainer {
          order: -1;
        }
      }

      &.isBleed {
        --single-media-column-start: calc(var(--grid-number-of-columns) / 2 + 2);
        --single-media-column-span: calc(var(--grid-number-of-columns) / 2 + 1);
        --lockup-grid-start: col 1;
      }
    }
  }
}

.contentWrapper {
  composes: subgrid from '~g';
  z-index: var(--z-index-2);
  flex-flow: column;
  margin-bottom: var(--spacer-40);
  place-content: center;
  pointer-events: initial;

  .noTitleAndContent & {
    margin-bottom: 0;
  }

  @media (--md) {
    margin-bottom: 0;
    grid-row: 1;

    --content-wrapper-start: col 1;
    --content-wrapper-width: 4;
  }
}

.content {
  & + & {
    margin-top: var(--spacer-24);

    @media (--md) {
      margin-top: var(--spacer-48);
    }
  }
}

.touts {
  --touts-content-width: var(--grid-number-of-columns);
  margin-top: var(--spacer-40);
  grid-column: var(--lockup-grid-start, start) / span var(--touts-content-width);

  .noTitleAndContent & {
    margin-top: 0;

    @media (--md) {
      margin-top: 0;
    }
  }

  .noTitleAndContent.isMultiImage & {
    margin-top: var(--spacer-40);

    @media (--md) {
      margin-top: 0;
    }
  }

  @media (--md) {
    --touts-content-width: 3;
    margin-top: var(--spacer-48);
  }
}

.toutsContainer {
  @media (--sm-only) {
    row-gap: var(--spacer-32);
  }
}

.mediaContainer {
  position: relative;
  aspect-ratio: 0.888;
}

.isMobile {
  display: initial;

  @media (--md) {
    display: none;
  }
}

.isTablet {
  display: none;

  @media (--md) {
    display: initial;
  }
}

.linkList {
  composes: listItemsByIndex from '~listItemsByIndex';

  --link-list-content-width: var(--grid-number-of-columns);
  margin-top: var(--spacer-40);
  grid-column: var(--lockup-grid-start, start) / span var(--link-list-content-width);

  @media (--md) {
    margin-top: var(--spacer-48);

    --link-list-content-width: 4;
  }

  @media (hover: hover) {
    &:has(.linkListItem:hover) {
      .linkListItemLink {
        color: var(--deactivated-color, var(--color-alpha-light-20));

        &::before {
          color: var(--deactivated-color, var(--color-alpha-light-20));
        }
      }
    }
  }
}

.linkListItem {
  composes: listItemsByIndexItem from '~listItemsByIndex';

  /* Need to make sure we override the above */
  /* stylelint-disable declaration-no-important */
  @media (hover: hover) {
    &:hover {
      .linkListItemLink {
        color: var(--color-alpha-grey-100) !important;
        transition: color var(--duration-300ms) var(--ease-out) !important;

        &::before {
          color: var(--color-alpha-light-50) !important;
          transition: color var(--duration-300ms) var(--ease-out) !important;
        }
      }

      .linkListItemLabel {
        transform: translateX(0.25rem) !important;
        transition: transform var(--duration-300ms) var(--ease-basic-butter) !important;
      }
    }
  }
  /* stylelint-enable declaration-no-important */
}

.linkListItemLink {
  composes: listItemsByIndexItemLink from '~listItemsByIndex';
}

.linkListItemLabel {
  composes: listItemsByIndexItemInner from '~listItemsByIndex';
}
