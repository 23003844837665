.mediaWithText {
  composes: grid from '~g';
  position: relative;
  width: 100%;
  margin: 0;

  --media-with-text-progress: 0;
  --max-parallax-percent: 0;

  @media (--md) {
    grid-column: start / end;
  }
}

.contentWrapper {
  position: relative;
  z-index: var(--z-index-1);
  width: 100%;
  height: 100%;
}

.mediaContainer {
  position: absolute;
  overflow: hidden;
  inset: 0;
}

.hasRoundedBorders {
  overflow: hidden;
  border-radius: var(--border-radius-5);
}

.mediaWrapper {
  --default-media-start: start;
  --default-media-end: end;
  position: relative;
  display: flex;
  width: 100%;
  grid-column: var(--media-start, var(--default-media-start)) / var(--media-end, var(--default-media-end));

  @media (--md) {
    --default-media-start: col 2;
    --default-media-end: col 14;
    width: auto;
  }

  &:not(.macbookPro, .studioDisplay) {
    @media (--lg) {
      --default-media-start: start;
      --default-media-end: end;
    }

    .contentWrapper {
      overflow: hidden;
    }

    .mediaContainer {
      position: relative;
      overflow: hidden;
    }

    .media {
      height: 100%;
      aspect-ratio: var(--media-original-aspect-ratio);
      transition: transform var(--duration-150ms) var(--ease-out);

      &:not(.noMediaParallax) {
        @media (--md) {
          transform: translate3d(0, calc((var(--media-with-text-progress) - 0.5) * (-1% * var(--max-parallax-percent))), 0) scale(calc(1 + (var(--max-parallax-percent) / 100)));
        }
      }
    }
  }
}

.media {
  width: 100%;
  height: 100%;
}

.isMobileLeftAligned,
.isMobileRightAligned {
  flex-shrink: 0;

  .mediaWrapper {
    width: min(166%, 60rem);

    /* TODO: sm has been deprecated (media with text) */
    @media (--md) {
      width: auto;
    }
  }

  .media {
    aspect-ratio: var(--media-original-aspect-ratio);
  }
}

.isMobileRightAligned {
  .mediaWrapper {
    justify-self: flex-start;

    @media (--md) {
      justify-self: unset;
    }
  }
}

.isMobileLeftAligned {
  .mediaWrapper {
    justify-self: flex-end;

    @media (--md) {
      justify-self: unset;
    }
  }
}

.textContent {
  z-index: var(--z-index-1);
  padding: 0;
  margin-top: var(--spacer-40);

  @media (--md) {
    margin-top: var(--spacer-48);
  }
}
