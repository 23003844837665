.dummy {
  position: relative;
  height: 0;
  align-self: center;
  padding-top: 51%; /* ratio */
  grid-column: start / end;
  pointer-events: none;
}

.modalBackground {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.modalShadow {
  position: absolute;
  top: 0;
  left: 0;
  width: var(--width, 100vw);
  height: var(--height, 51vw); /* 0.51 card ratio */
  opacity: 0;
}

.modalContent {
  composes: subgrid from '~g';
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  width: var(--width, 100vw);
  height: var(--height, 51vw); /* 0.51 card ratio */
  border-radius: var(--card-border-radius);
  grid-column: unset; /* for some reason it mess with top/left positioning */
  opacity: 0;
  transform-origin: top left; /* important for positioning at exact spot where the card was */

  .modalCarouselArrows {
    padding-right: var(--spacer-48);
    padding-bottom: var(--spacer-28);
    padding-left: var(--spacer-48);
    grid-column: start / end;
  }
}

.carousel {
  position: relative;
  display: flex;
  height: var(--height);
  align-items: center;
  justify-content: flex-start;
  grid-column: start / end;
  transform: translateX(calc(var(--carousel-index) * -100%));
  transition: transform var(--duration-300ms) var(--ease-basic-butter);
}

/* Carousel items */
.carouselItem {
  position: relative;
  min-width: var(--width);
  height: var(--height);
  padding: 0 var(--spacer-48) calc(8rem + var(--spacer-32)) var(--spacer-48);

  --title-4-pothole-column-start: col 1;

  /* shadow for readability */
  &::before {
    position: absolute;
    z-index: var(--z-index-1);
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, var(--transparent) -20%, var(--color-alpha-black-90) 108%);
    content: '';
    opacity: var(--shadow-opacity, 0);
    pointer-events: none;
  }
}

.quoteCardMedia {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  aspect-ratio: 1 / 0.51;
  transform: translate(-50%, -50%);

  .quoteCardMediaImg {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.quoteCardContent,
.quoteCardTitle {
  z-index: var(--z-index-2);
}

.quoteCardContent {
  composes: subgrid from '~g';
  position: relative;
  height: 100%;
  flex-direction: column;
  padding: var(--spacer-48) 0 0;
  grid-column: 1 / span 7;
  opacity: var(--item-progress);
  transform: translateX(calc(2rem - var(--item-progress) * 2rem));
}

.quoteCardTitle {
  composes: label from '~typo';
  display: block;
  grid-column: start / end;
}

.quoteCardText {
  --title-column-start: start;
  composes: title4 from '~typo';
  height: auto;
  grid-template-rows: unset;
  place-self: end;
}

.em,
.emWithDash {
  composes: labelEm from '~deco';
  color: var(--color-alpha-light-50);

  &::before {
    color: var(--color-grey-95);
  }
}

.closeButton {
  position: absolute;
  top: var(--spacer-40);
  right: var(--spacer-40);
  display: flex;
  width: 4rem;
  height: 4rem;
  align-items: center;
  justify-content: center;
  padding: 0;
  cursor: pointer;
  transform-origin: top right;

  &::before {
    position: absolute;
    border: 0.1rem solid var(--color-alpha-light-15);
    border-radius: 50%;
    content: '';
    inset: 0;
    transition: transform var(--duration-300ms) var(--ease-basic-butter);
  }

  &:hover {
    &::before {
      transform: scale(1.2);
    }
  }

  .buttonIcon {
    width: 1rem;
    height: 1rem;
    transform: rotate(calc(var(--rotate, 0) * 1deg));
    transform-origin: center center;
  }
}
