/*
  This component implements custom breakpoints that
  deviate from the global grid in between 768px and 1158px
  in order to preserve the 5-column table layout

  * 1240+ (lg): existing grid, desktop styles
  * 1158-1239 custom bp: existing grid, desktop styles
  * 960-1158 custom bp: 60px margin
  * 768-959 custom bp: 40px margin
  * 0-767 (sm): existing grid, mobile styles
*/
$smMdBreakpoint: 768px;
$mdBreakpoint: 960px;
$mdLgBreakpoint: 1158px;

@value smMdBreakpoint: $smMdBreakpoint;
@value mdBreakpoint: $mdBreakpoint;
@value mdLgBreakpoint: $mdLgBreakpoint;

.wrapper {
  composes: grid from '~g';
  background: var(--color-grey-00);
}

.pricingColumn {
  padding: var(--spacer-24) 0 0 0;
  border-bottom: 1px solid var(--color-grey-15);
  margin-right: var(--grid-gutter-size);

  @media (min-width: mdLgBreakpoint) {
    padding: 0;
  }
}

.cardTitle {
  composes: eyebrow from '~typo';
  color: var(--color-accent);
}

.cardPrice {
  composes: title6 from '~typo';
  margin-bottom: var(--spacer-20);
  color: var(--color-grey-70);
}

.cardDetailButton {
  padding: 1.4rem var(--spacer-16) 1.3rem;
  margin-bottom: var(--spacer-24);

  @media (--md) {
    margin-bottom: var(--spacer-28);
  }

  @media (min-width: mdLgBreakpoint) {
    margin-bottom: var(--spacer-24);
  }
}

.pricingCategory {
  width: 100%;
}

.headingsRow {
  .pricingColumn {
    @media (min-width: mdLgBreakpoint) {
      padding-top: calc(var(--nav-height) + var(--spacer-20));
    }
  }
}

.checkmarkComparisonCheck,
.checkmarkComparisonNoCheck {
  position: relative;
  display: block;
  width: 1.8rem;
  height: 1.8rem;
}

.checkmarkComparisonCheck {
  * {
    fill: var(--color-accent);
  }
}

.checkmarkComparisonNoCheck {
  composes: label from '~typo';
  width: auto;
}

.pricingTable .checkmarkComparisonNoCheck {
  display: flex;
  align-items: center;
  color: var(--color-grey-100);
}

.checkmarkComparisonTitle {
  composes: label from '~typo';
  position: relative;
  display: inline-block;
  max-width: max(65%, 12rem);
  color: var(--color-alpha-light-50);

  @media (--md) {
    max-width: max(50%, 14rem);
  }
}

.container {
  composes: grid from '~g';
  grid-column: wrapper-start / wrapper-end;
}

.header {
  composes: subgrid from '~g';
  z-index: var(--z-index-1);
  padding: var(--spacer-28) 0 0;
  border-bottom: 0;

  /* backdrop-filter: blur(15px); */
  grid-column: wrapper-start / wrapper-end;
  transform: translateY(0);

  @media (--md) {
    padding: var(--spacer-40) 0;
    border-bottom: 1px solid var(--color-alpha-light-20);
    grid-column: start / end;
  }

  @media (min-width: smMdBreakpoint) {
    padding: var(--spacer-32);
    grid-column: wrapper-start / wrapper-end;
  }

  @media (min-width: mdBreakpoint) {
    padding: var(--spacer-40) 6rem;
  }

  @media (min-width: mdLgBreakpoint) {
    padding: var(--spacer-40) 0;
    grid-column: start / end;
  }

  &.isStuck {
    position: sticky;
    top: 0;
    backdrop-filter: blur(15px);
    background: var(--nav-gradient-when-unblurred);
    transition: transform 0.12s var(--ease-default);

    @media (min-width: mdLgBreakpoint) {
      transform: translateY(0);
      transition: transform 0.3s var(--ease-default);
    }
  }

  &.isStuck.isDesktopNavigationShowing {
    transition: transform 0.12s var(--ease-default);

    /* TODO: sm has been deprecated (pricing table) */
    @media (--md) {
      transition: transform 0.3s var(--ease-default);
    }
  }
}

.heading {
  composes: subgrid from '~g';
  padding-left: var(--grid-computed-margin-width);
  grid-column: start / end;

  --subgrid-width: 1;

  @media (--md) {
    padding-left: 0;
    grid-column: start / span 4;
  }
}

.headingLockup {
  /* Disable the grid within lockup */
  display: block;
}

.desktopPlanHeadersWrapper {
  display: none;

  @media (--md) {
    display: flex;
    flex-direction: row;
    gap: var(--grid-gutter-size, 0);
    grid-column: 5 / end;
    place-content: space-between space-evenly;
  }

  @media (min-width: smMdBreakpoint) {
    gap: 1rem;
  }
}

.desktopPlanHeaders {
  display: none;

  @media (--md) {
    display: initial;
    flex: 1;
    grid-column: span 2;
  }
}

.desktopHeaderTitle,
.desktopHeaderSubtitle {
  composes: title4 from '~typo';

  /* not quite our standard letter spacing but here we are again */
  @media (--md) {
    letter-spacing: -0.06rem;
  }

  @media (--lg) {
    letter-spacing: -0.075rem;
  }
}

.desktopHeaderTitle {
  color: var(--grey-brand-grey-100, #fcfcfc);
}

.desktopHeaderSubtitle {
  margin-top: -0.04rem;
  color: var(--alpha-brand-alpha-light-50, rgb(234 234 255 / 50%));
}

.planHeaders {
  composes: subgrid from '~g';
  padding-top: var(--spacer-40);
  padding-bottom: 0;
  grid-column: start / end;

  .isStuck & {
    padding-bottom: var(--spacer-40);
  }

  @media (--md) {
    display: none;
  }
}

.planInner {
  display: flex;
  width: auto;
  flex-direction: row;
  padding: 0;
  grid-column: start / end;
  overflow-y: scroll;

  @media (--md) {
    padding: var(--spacer-10);
  }
}

.tableBody {
  composes: subgrid from '~g';
  padding: 0;
  grid-column: wrapper-start / wrapper-end;

  @media (min-width: smMdBreakpoint) {
    padding: var(--spacer-40);
  }

  @media (min-width: mdBreakpoint) {
    padding: var(--spacer-40) 6rem;
  }

  @media (min-width: mdLgBreakpoint) {
    padding: 0;
    grid-column: start / end;
  }
}

.pricingPlanButton {
  width: 205px;
  height: var(--spacer-48);
  margin-top: 0;
  backdrop-filter: blur(15px);
  opacity: 0.4;
  transition: var(--duration-500ms) var(--ease-basic-butter);

  &:not(:last-of-type) {
    margin-right: var(--spacer-10);
  }

  @media (--sm) {
    /* rm border on mobile */
    &::after {
      display: none;
    }
  }

  /* note: we are doing some funky stuff here since the live copy occupies the entire two columns by itself on smaller end of medium and does not leave room for horizontal padding on the button. if instead we specify a horizontal padding we break the grid as the button becomes wider than 2 colums. */
  @media (--md) {
    width: 100%;
    height: auto;
    padding: 1.45rem 0 1.5rem;
    margin-top: var(--spacer-12);
    margin-right: 0;
    opacity: 1;

    @media (min-width: smMdBreakpoint) {
      padding: 1rem 0;
    }

    &::after {
      display: block;
    }
  }

  @media (min-width: smMdBreakpoint) {
    font-size: 1.2rem;
  }

  @media (--lg) {
    padding: 1.45rem 3.7rem 1.5rem;
  }

  &.selectedPlan {
    opacity: 1;
  }
}

.pricingPlanTitle {
  margin-right: var(--spacer-6);
  color: var(--color-cobalt);
}

.categoryTitle {
  composes: bodyMedium from '~typo';

  /* override AccordionItem default styling - flip caret to the left */
  flex-direction: row-reverse;
  justify-content: flex-end;
  padding-bottom: 1rem;
  margin-top: var(--spacer-48);
  color: var(--color-grey-100);
  grid-column: start / end;

  & svg {
    width: 1rem;
    height: 1rem;
    margin-right: var(--spacer-12);
    margin-left: 0;

    path {
      fill: var(--color-grey-100);
    }
  }
}

.categoryRow {
  composes: subgrid from '~g';
  height: auto;
  padding: 0;
  border-bottom: 1px solid var(--color-grey-20);
  margin: 0 var(--grid-margin-size);
  grid-column: start / end;

  @media (--md) {
    margin: 0;
  }
}

.featureComparisonTitle {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.featureComparisonTitle,
.featureComparisonSubtitle {
  composes: body from '~typo';
}

.featureTitle {
  position: relative;
  padding: var(--spacer-16) 0;
  grid-column: start / end;

  @media (--md) {
    grid-column: start / span 4;
  }
}

.features {
  /* override AccordionItem content default styling */
  padding-right: 0;
  grid-column: start / end;

  @media (--md) {
    padding-right: 0;
  }

  & > :first-child {
    padding-top: 0;
  }
}

.featureRow {
  composes: subgrid from '~g';
  border-bottom: 1px solid var(--color-grey-20);
  grid-column: start / end;

  &:last-of-type {
    border-bottom: 0;
  }
}

.mobileActiveValue {
  display: unset;
  margin-left: var(--spacer-4);
  text-align: right;

  @media (--md) {
    display: none;
  }
}

.featureLimitsWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--grid-gutter-size, 0);
  grid-column: start / end;
  place-content: space-between space-evenly;

  @media (--md) {
    grid-column: 5 / end;
  }

  @media (min-width: smMdBreakpoint) {
    gap: 1rem;
  }
}

.featureLimit {
  composes: body from '~typo';
  display: none;

  /* grid-column: span 2; */
  flex: 1;
  padding: var(--spacer-16) 0;

  @media (--md) {
    display: unset;
  }
}

.sliderContainer {
  display: flex;
  min-height: auto;
  grid-column: start / end;

  /* we have to manually adjust the height of the slider container when accordions collapse.
    The transition here should match the one in AccordionItem.
  */
  transition: height var(--duration-500ms) var(--ease-basic-butter);

  @media (--md) {
    display: none;
  }
}

.slide {
  width: 100%;
  min-height: auto;
  flex-shrink: 0;
}

.headerSlider {
  position: relative;
  display: flex;
  overflow: hidden;
  flex-direction: row;
  align-content: flex-start;
  touch-action: pan-y;
}

.headerSlide {
  overflow: visible;
}

.stickyCtaContainer {
  position: fixed;
  z-index: var(--z-index-1);
  bottom: 0;
  display: flex;
  width: 100vw;
  height: var(--element-height);
  flex-direction: row;
  padding: var(--spacer-24) var(--grid-margin-size);
  border: 0.1rem solid var(--color-alpha-light-10);
  border-radius: var(--border-radius-20) var(--border-radius-20) 0 0;
  background: var(--color-grey-08);
  transform: translateY(var(--element-height));
  transition: transform var(--duration-500ms) var(--ease-basic-butter);

  --element-height: 8.8rem;

  @media (--md) {
    display: none;
  }

  &.sticky {
    transform: none;
  }
}

.stickyCta {
  width: 100%;

  > span {
    width: 100%;
  }
}

.stickyCtaSlider {
  position: relative;
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 100%;
  min-height: 1.5rem;
  align-content: flex-start;
}

.ctaSlide {
  text-align: center;
}

.desktopCategory {
  display: none;

  @media (--md) {
    display: unset;
  }
}

.disclaimerContainer {
  display: flex;
  flex-direction: column;
  padding-top: var(--spacer-48);
  grid-column: start / end;

  @media (--sm-only) {
    margin: 0 var(--grid-margin-size);
  }
}

.disclaimer {
  composes: footnote from '~typo';
  padding-top: var(--spacer-4);
  color: var(--alpha-brand-alpha-light-50, rgb(234 234 255 / 50%));
}
